@charset "UTF-8";

/*******************************

    header

*******************************/

.head {
  background: $white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  display: flex;

  @include lt-tblt {
    padding: 0 6rem;
    justify-content: space-between;
    height: $pc_menu_Height;

    @include max-screen($pc_Width_l) {
      padding: 0 2rem;
    }
  }

  @include st-tblt {
    height: 80px;
    align-items: center;
    padding-left: 1rem;

  }

  @include st-sp {
    height: 50px;
  }

  &-logo {

    @include lt-tblt {

      &.is-loaded {
        opacity: 1;
      }

      padding-top: 1rem;

      a {
        @include hover01;
      }
    }

    @include st-tblt {


      img {
        width: 20rem;
      }
    }

  }
}

.menu-main {
  @include font_Serif;

  a {}

  // @include lt-tblt{
  //   transition: opacity 1.75s ease-out 2s , transform .75s ease-out 2s;
  //   transform: translateX(5rem);
  //   opacity: 0;
  //   &.is-loaded{
  //     transform: translateX(0rem);
  //     opacity: 1;
  //   }
  // }
}


@include lt-tblt {


  .menu-main {
    height: 4.5rem;
    padding-right: 11rem;
    @include max-screen($pc_Width_l) {
      padding-right: 13rem;
    }

    .menu-inner,
    nav {
      height: 100%;
    }

    ul {
      display: flex;
      height: 100%;
    }

    li {
      display: flex;
      align-items: flex-end;
      height: 100%;
      padding: 0 2rem;

      &:not(:last-of-type) {
        border-right: 1px solid $black;
      }

      &:last-of-type {
        padding-right: 0;
      }

      @include max-screen(1399px) {
        padding: 0 1rem;
      }
    }

    a:not(.bnr) {

      font-size: 1.6rem;
      letter-spacing: $ls_120;
      @include max-screen(1399px) {
        font-size: 1.3rem;
      }

      &::after {
        content: "";
        display: block;
        width: 0;
        height: 1px;
        background: $black;
        transition: $transition;
      }

      &:hover::after {
        width: 100%;
      }
    }
  }
}




@include st-tblt {
  .menu {
    &-main {
      display: none;
      z-index: 90002;
      opacity: 0;
      transition: ease .3s;
    }
  }

  .menu.is-open {
    .menu {
      &-main {
        font-size: $fz_m;
        display: block;
        position: fixed;
        background: rgba($white, .95);
        top: 0;
        height: 100%;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        width: 100%;
        left: 0;

        nav>ul {
          li {
            &:not(:last-of-type) {
              margin-bottom: 3rem;
            }
          }

          a {
            font-size: 2rem;
            padding: .5rem 0 1.5rem;
            line-height: 1;
            display: block;
            width: 100%;
            border-bottom: 1px solid $black02;
            color: $black;
          }
        }

        .menu-inner,
        nav {
          width: 100%;
        }

      }

      &-inner {
        padding: $boxSpace_s;
        @include flex;
        @include fl-item(center);
        @include fl-space(center);
        min-height: 100%;
      }

    }

    &.is-active {
      .menu-main {
        opacity: 1;
      }

      .menu-inner {
        transform: translate3d(0%, 0, 0);
      }
    }
  }


  .head-menu_btn {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99999;
    width: 80px;
    height: 80px;
    background: $black02;
    flex: 0 0 auto;
    @include flex;
    @include fl-item;
    @include fl-space(center);
    cursor: pointer;

    @include st-sp {
      width: 50px;
      height: 50px;
    }

    i {
      width: 42px;

      @include st-sp {
        width: 24px;
      }
    }

    span {
      height: 1px;
      background: $white;
      display: block;
      transition: $transition;

      &:first-of-type {
        width: 100%;
        margin-bottom: 12px;

        @include st-sp {
          margin-bottom: 6px;
        }
      }

      &:nth-of-type(2) {
        width: 100%;
        margin-bottom: 12px;

        @include st-sp {
          margin-bottom: 6px;
        }
      }
    }

    &:hover {
      span {
        &:nth-of-type(2) {
          //width: 75%;
        }
      }
    }

    &.menu-open {
      span {
        margin: 0;

        &:first-of-type {
          transform: rotate(45deg);
          margin: 0;
        }

        &:nth-of-type(2) {
          display: none;
        }

        &:last-of-type {
          transform: rotate(-45deg);
          width: 100%;
          margin: -1px 0 0 0px;
        }
      }
    }
  }

}

.menu-sns {
  @include lt-tblt {
    position: fixed;
    right: 1.4rem;
    top: 16.5rem;
  }

  @include st-tblt {
    margin-top: 4rem;
  }

  &-list {
    @include st-tblt {
      display: flex;
      justify-content: center;
    }

    li {
      margin-bottom: 2.5rem;

      @include st-tblt {
        margin: 0 1.5rem;
        display: flex;
        align-items: center;
        transform: scale(1.25);
      }
    }

    a {
      display: block;
      width: 1.6rem;
      text-align: center;
      @include hover01;

      @include st-tblt {
        display: flex;
        align-items: center;
        width: 2rem;
      }
    }
  }
}

.menu-bnr{
  @include lt-tblt{
    position: absolute;
    right: 0;
    top: 0;
  }
  @include st-tblt{
    margin-top: 5rem;
  }
  a{
    @include lt-tblt{
      @include hover01;
    }
  }
  img{
    @include lt-tblt{
      width: 14rem;
    }
  }
}
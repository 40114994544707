@charset "UTF-8";

/*******************************

    BASE

*******************************/

html,
body {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

html {
  position: relative;
  @include default_font;
  //@include kerning;
}

body {
  background: $white;
  font-size: 1.45rem;
  color: $txt_color;
  letter-spacing: $ls_70;

  @include st-sp {
    // padding: 0 1.5rem;
  }
}

body.fixed {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
}

img {
  max-width: 100%;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

a {
  text-decoration: none;
  color: $txt_color;
}

p {
  word-wrap: break-word;
}

p img {
  vertical-align: bottom;
  max-width: 100%;
}

/* DISPLAY
------------------------------*/

@include lt-tbmin {
  .lt-tbminNone {
    display: none !important;
  }
}

@include st-tbmin {
  .st-tbminNone {
    display: none !important;
  }
}

@include lt-tblt {
  .lt-tbltNone {
    display: none !important;
  }
}

@include st-tblt {
  .st-tbltNone {
    display: none !important;
  }
}

@include lt-sp {
  .lt-spNone {
    display: none !important;
  }
}

@include st-sp {
  .st-spNone {
    display: none !important;
  }
}

//@include lt-pc {
//  .lt-pcNone {
//    display: none !important;
//  }
//}

@include tblt {
  .tbltNone {
    display: none !important;
  }
}


[class$="_ttl"],
[class*="_ttl "] {
  position: relative;
  z-index: 1;
}

img[src$="_sp.svg"],
img[src$="_sp.jpg"],
img[src$="_sp.png"] {
  @include lt-tblt {
    display: none;
  }
}

img[src$="_pc.svg"],
img[src$="_pc.jpg"],
img[src$="_pc.png"] {
  @include st-tblt {
    display: none;
  }
}

@include tblt {

  //PC画像をtbltで使いたい
  img[src$="_pc.svg"]:not(.tbon),
  img[src$="_pc.jpg"]:not(.tbon),
  img[src$="_pc.png"]:not(.tbon) {
    @include st-tblt {
      display: none;
    }
  }

  //sp画像をtbltで消したい
  img[src$="_sp.svg"].tbnone,
  img[src$="_sp.jpg"].tbnone,
  img[src$="_sp.png"].tbnone {
    @include st-tblt {
      display: none;
    }
  }
}
@charset "UTF-8";



/*******************************

    FONT

*******************************/

@mixin cmn-ttl01{

}

@mixin cmn-ttl02{
}


/*******************************

    ARROW

*******************************/

@mixin arrow-side-dots{
  position: absolute;
  @include st-tblt{
    left: 12rem;
  }
  @include st-sp{
   width: calc(100% - 11rem);
   left: inherit;
   right: 0;
  }
}


@mixin arrow-b{
  display: flex;
  align-items: center;
  &:after{
    content: "";
    display: block;
    background: url(../img/icon-arrow-b.svg)center center no-repeat;
    width: 10px;
    height: 7px;
    margin-left: 15px;
  }
}


@mixin arrow{
  display: flex;
  align-items: center;
  &:after{
    content: "";
    display: block;
    background: url(../img/icon-arrow.svg)center bottom no-repeat;
    width: .5rem;
    height: 1.2rem;
    margin-left: .75rem;
  }
}
@mixin link-o{
  display: flex;
  align-items: center;
  &:after{
    content: "";
    display: block;
    background: url(../img/icon-link-o.svg)center bottom no-repeat;
    width: 1.8rem;
    height: 1.4rem;
    margin-left: .75rem;
  }
}
@mixin link-g{
  display: flex;
  align-items: center;
  &:after{
    content: "";
    display: block;
    background: url(../img/icon-link-g.svg)center bottom no-repeat;
    width: 1.8rem;
    height: 1.4rem;
    margin-left: .75rem;
  }
}

/*******************************

    BLOCK
    
*******************************/

@mixin maw($v:820px) {
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 36rem);
  @include max-screen(){
  width: calc(100% - 20rem);
  }

  @include lt-sp {
    max-width: $v;
  }

  @include st-tblt{
    width: calc(100% - 12rem);
    padding-left: 3.3rem;
  }
  @include st-sp{
    width: calc(100% - 6rem);

  }
}
@charset "utf-8";
@import "settings";
@import "mixinparts";
@import "base";

body {
  position: relative;
}

// .test-wrap {
//   background: rgba(255, 255, 255, 1);
//   position: absolute;
//   top: 0;
//   min-height: 9233px;
//   width: 100%;
//   z-index: -1;
// }


main {
  @include lt-tblt {
    margin: $pc_menu_Height auto 0;
    max-width: 1300px;
  }

  @include st-tblt {}
}

@mixin maw($v:$pc_Width) {
  max-width: calc(#{$v} + 6rem);
  padding: 0 3rem;
  margin-left: auto;
  margin-right: auto;

  @include st-tblt {
    max-width: calc(#{$v} + 4rem);
    padding: 0 2rem;
  }
}

@mixin maw-fix($v:$pc_Width) {
  max-width: $v;
  margin-left: auto;
  margin-right: auto;

  @include st-tblt {}
}

@mixin sp-ttlmb {
  @include st-tblt {
    margin-bottom: 3rem;
  }
}


.cmn-inner {
  //@include maw-fix();
}


@mixin cmn_ttl01 {
  width: 80%;
  max-width: 32.5rem;
  border-bottom: 1px solid $black;
  text-align: right;
  font-size: 2.4rem;
  letter-spacing: $ls_70;
  padding-bottom: 1rem;
  font-weight: 600;

  @include st-tblt {
    width: 50%;
    margin-bottom: 6rem;
  }
}

.cmn_ttl01 {
  @include cmn_ttl01;
}

@mixin cmn_btn01 {
  a {
    background: $black;
    align-items: center;
    @include arrow;
    display: inline-flex;
    color: $white;
    height: 3rem;
    border-radius: 1.5rem;
    padding: 0 1.5rem;
    line-height: 3rem;
    font-weight: 600;
    //padding-bottom: .2em;
    @include hover01;
    font-size: 1.6rem;
    white-space: nowrap;
  }
}

.cmn_btn01 {
  @include cmn_btn01;
}

.cmn_btn01-p {
  @include cmn_btn01;

  a {

    background: #ba5449;
  }
}


.cmn_btn02-o {
  a {
    font-size: 1.6rem;
    @include link-o;
    display: inline-flex;
    border: 1px solid #be7333;
    background: #f0e0d2;
    color: #be7333;
    @include hover01;
    padding: 1rem 1.25rem;
  }
}

.cmn_btn02-g {
  a {
    font-size: 1.6rem;
    @include link-g;
    display: inline-flex;
    border: 1px solid #526b2a;
    background: #c0d3b1;
    color: #526b2a;
    @include hover01;
    padding: 1rem 1.25rem;
  }
}


article {
  position: relative;
}

footer {
  //display: none !important;
}

/*******************************

    topKv

*******************************/


/* kv
-----------------------------*/

.topKv {
  position: relative;
  @include fluid_space($v:11rem);

  @include lt-tblt {
    min-height: 630px;
    height: calc(100vh - #{$pc_menu_Height});
  }

  @include st-tblt {
    min-height: 500px;
  }
  &-wrap{
    height: 100%;
    position: relative;
    overflow: hidden;

  @include fluid_space($v:11rem);

  @include lt-tblt {
    min-height: 630px;
    height: calc(100vh - #{$pc_menu_Height});
  }

  @include st-tblt {
    min-height: 500px;
  }
  }
  &-inner {
    height: 100%;
    position: relative;

    @include st-tblt {
      overflow: hidden;
    }
  }

  &_ttl {
    position: absolute;
    bottom: 7rem;
    left: 6rem;
    z-index: 1;

    // @include font_Serif;
    // font-size: 7.2rem;
    // letter-spacing: $ls_120;
    @include lt-tblt {

      max-width: 40%;
    }

    @include st-tblt {
      bottom: 10vw;
      left: 3vw;
      width: 63vw;
    }
  }

  &_copy {
    position: absolute;



    // @include tategaki;
    // @include font_Serif;
    // font-size: 2.2rem;
    // font-weight: 600;
    // letter-spacing: .4em;
    // line-height: 1.65;
    // z-index: 1;
    span {
      display: block;
    }
  }

  //animation
  //   &Head-copy {
  //     opacity: 0;
  //     transition: opacity 1s cubic-bezier(.43, .13, .56, .72);
  //   }

  //   &Lower_logo {
  //     opacity: 0;
  //     transition: opacity 1s cubic-bezier(.43, .13, .56, .72) 3s;
  //   }

  //   &Head::before {
  //     content: "";
  //     display: block;
  //     position: absolute;
  //     width: 100%;
  //     height: 100%;
  //     top: 0;
  //     left: 0;
  //     background: $lemon;
  //     opacity: 1;
  //     transition: opacity 1s cubic-bezier(.43, .13, .56, .72) 2s;
  //   }
  //   &Head-inner::before {
  //     content: "";
  //     display: block;
  //     position: absolute;
  //     width: 100%;
  //     height: 100%;
  //     top: 0;
  //     left: 0;
  //     background: $lemon;
  //     opacity: 1;
  //     transition: opacity 1s cubic-bezier(.43, .13, .56, .72) 1s;
  //   }

  //   &.is-loaded &Head-copy,
  //   &.is-loaded &Lower_logo {
  //     opacity: 1;
  //   }

  //   &.is-loaded &Head::before,
  //   &.is-loaded &Head-inner::before {
  //     opacity: 0;
  //   }
  // }
}


.topKv01{
  .inner{
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: calc(100% - 6rem);
    background: url(../img/top-kv.jpg)center top no-repeat;
    background-size: contain;
    @include st-tblt {
      background: url(../img/top-kv_sp.jpg)center top no-repeat;
      background-size: contain;
    }
  }
  }
  .copy{
    @include lt-tblt {
      bottom: 5.5rem;
      right: 14.5rem;
    }

    @include st-tblt {
      right: 6vw;
      top: 70vw;
      width: 20vw;
    }
  }
}

.topKv02{
  .inner{
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: calc(100% - 6rem);
    background: url(../img/top-kv02.jpg)center top no-repeat;
    background-size: contain;
    @include st-tblt {
      background: url(../img/top-kv02_sp.jpg)center top no-repeat;
      background-size: contain;
    }
  }
  }
  .copy{
    @include lt-tblt {
      bottom: 7.5rem;
      right: 14.5rem;
    }

    @include st-tblt {
      right: 6vw;
      top: 78vw;
      width: 20vw;
    }
  }
}

img {
  vertical-align: bottom;
}

.cmn-anchor{
  margin-top: -8rem;
  padding-top: 8rem;
  @include st-sp{
    margin-top: -5rem;
    padding-top: 5rem;
  }
}

/* news
-----------------------------*/

.topNews {
  @include fluid_space($v:17.5rem);

  &_ttl {
    @include lt-tblt {

      margin-bottom: 6rem;
    }
  }

  &-cont {
    @include maw($pc_Width_m);
  }

  &-list {
    @include block-3col($mr:7rem, $spcol:'2');
    margin-bottom: 3rem;
    a{
      @include block-a;
      @include lt-sp {
        transition: ease 300ms;
        &:hover {
          opacity: .65;
        }
      };
    }

    &_img {
      @include trim('col');
      @include trim-size(60%);
      margin-bottom: 2.5rem;
    }

    &-cont {

      &_cat {
        font-size: $fz_s;
        color: $white;
        font-weight: 600;
        display: inline-block;
        background: black;
        padding: .5rem 1rem;
        margin-bottom: 1rem;
      }

      &_day {
        width: 100%;
        font-size: 1.2rem;
        margin-bottom: 1rem;
      }

      &_ttl {
        line-height: 2;
      }
    }
  }

  li {

    display: flex;
  }
}

/* concept
-----------------------------*/

@mixin topCopy {
  @include tategaki;
  font-size: 3rem;
  line-height: 1.8;
  letter-spacing: $ls_120;
  font-weight: 500;
  max-height: 25rem;
}


.topConcept {
  @include fluid_space($v:14rem);

  &-inner {
    position: relative;

    @include lt-tblt {

      display: flex;
      align-items: flex-start;
    }

    @include st-tblt {
      display: block;
    }
  }

  &-cont {
    width: calc(42% + 6rem);
    background: $gray;
    box-shadow: 15px 15px 35px rgba($black, .25);

    @include fluid_space($s:'padding', $p:'top', $v:6rem);
    @include fluid_space($s:'padding', $v:10rem);

    @include lt-tblt {
      margin-right: -6rem;
      order: -1;
    }

    @include st-tblt {
      width: $sp_cont;
      padding-right: 3rem;
      padding-top: 6rem;
      margin-top: -4rem;
    }

    &_ttl {
      @include lt-tblt {

        max-width: 36rem;
        margin-bottom: 4rem;
      }
    }

    &-inner {
      margin: 0 auto;

      @include lt-tblt {
        max-width: calc(30rem + 18rem);
        padding-left: 18rem;
      }

      @include st-tblt {
        padding-left: calc(20vw + 2rem);
      }
    }

    &_copy {
      @include topCopy();
      //margin-left: -.35em;
      @include fluid_space($v:8rem);
    }

    &_txt {
      margin-bottom: 3rem;
      line-height: 2;
    }
  }

  &_img {
    @include lt-tblt {
      max-width: 58%;
      //max-width: 814px;
      flex: 0 0 auto;
    }

    @include st-tblt {
      width: $sp_cont;
      margin: 0 0 0 auto;
    }

    img {
      width: 100%;
    }
  }

  &-parts01 {
    position: absolute;
    left: 0;
    bottom: -3rem;

    @include st-tblt {
      bottom: -6vw;
      width: 20vw;
    }
  }
}


/* alternative
-----------------------------*/

.topAlt {
  @include fluid_space;
  &-inner {
    overflow: hidden;
  }

  &_ttl {
    @include lt-tblt {
      margin-bottom: 8rem;
    }
  }

  &-cont {
    @include lt-tblt {
      @include maw(1335px);
      margin-right: -220px;
    }

    &_ttl {
      font-size: 2.5rem;
      letter-spacing: .22em;
      margin-bottom: 3.5rem;
      font-weight: 600;

      @include st-tblt {
        padding: 0 2rem;
      }
    }

    &-list {
      margin-bottom: 4.5rem;

      li {

        margin-right: 4.5rem;

        @include st-tblt {
          margin: 0 2rem;
        }
      }

      &_img {
        @include trim;
        @include trim-size;
        margin-bottom: 2rem;
      }

      &_ttl {
        line-height: 1.5;
        letter-spacing: $ls_120;
      }
    }

    &_more {

      @include st-tblt {
        padding: 0 2rem;
      }
    }
  }
}

/* story
-----------------------------*/

.topStory {
  @include fluid_space($v:14rem);

  &-inner {
    position: relative;

    @include lt-tblt {

      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  &-cont {

    width: 35%;
    @include fluid_space($s:'padding', $p:'top', $v:6rem);
    @include fluid_space($s:'padding', $v:10rem);

    @include st-tblt {

      width: $sp_cont;
      margin: 0 0 0 auto;
    }

    &-inner {
      margin: 0 auto 0 0;
      padding-left: 4.5rem;

      @include lt-tblt {
        max-width: calc(30rem + 4.5rem);
      }

      @include st-tblt {}
    }

    &_ttl {
      @include lt-tblt {
        width: 100%;
        margin-bottom: 7rem;
        max-width: 45rem;
      }

      margin-left: auto;
      text-align: left;
    }

    &_copy {
      @include topCopy();
      //margin-left: -.35em;
      @include fluid_space($v:8rem);
    }

    &_txt {
      margin-bottom: 3rem;
      line-height: 2;
    }
  }

  &_img {
    flex: 0 0 auto;
    width: 65%;

    //max-width: 902px;
    @include st-tblt {
      width: $sp_cont;
    }

    img {
      width: 100%;
    }
  }

  &-parts01 {
    position: absolute;
    right: 0;

    @include lt-tblt {

      top: 23rem;
    }

    @include st-tblt {
      bottom: -10vw;
      width: 40vw;
      z-index: -1;
    }
  }
}

/* company
-----------------------------*/

.topCompany {
  @include fluid_space($v:20rem);

  &-inner {
    position: relative;

    @include lt-tblt {
      display: flex;
      align-items: flex-start;
    }

  }

  &-cont {
    width: calc(44% + 3rem);
    background: $gray;
    box-shadow: 15px 15px 35px rgba($black, .25);

    @include fluid_space($s:'padding', $p:'top', $v:8rem);
    @include fluid_space($s:'padding', $v:9rem);

    @include lt-tblt {
      margin-top: 5rem;
      margin-right: -3rem;
      order: -1;
    }

    @include st-tblt {
      margin-top: -2rem;
      width: $sp_cont;
      position: relative;
      z-index: 1;
    }

    &_ttl {
      max-width: 36rem;
      margin-bottom: 4rem;
    }

    &-inner {
      margin: 0 auto;

      @include lt-tblt {
        max-width: calc(30rem + 18rem);
        padding-left: 18rem;
      }

      @include st-tblt {
        padding-left: 4.5rem;
        padding-right: 4.5rem;
      }
    }

    &_copy {
      @include topCopy();
      writing-mode: inherit;
      //margin-left: -.35em;
      margin-bottom: 1rem;
    }

    &_txt {
      margin-bottom: 4rem;
      line-height: 2;
    }
  }

  &_img {
    flex: 0 0 auto;
    width: 56%;

    @include st-tblt {
      width: 100%;
    }

    //max-width: 786px;
    img {
      width: 100%;
    }
  }

  &-parts01 {
    position: absolute;
    left: 0;
    bottom: -3rem;
  }
}

/* brands
-----------------------------*/

.topBrands {
  @include fluid_space($v:17rem);

  &_ttl {
    @include lt-tblt {

      margin-bottom: 6rem;
    }

  }

  .brandsBox {
    @include lt-tblt {
      display: flex;
    }



    &:not(:last-of-type) {
      margin-bottom: 9rem;
    }

    &-cont {
      width: 29rem;
      flex: 0 0 auto;
      margin: 0 4rem;

      @include st-tblt {
        width: 60%;
      }

    }

    &_img {
      @include st-tblt {

        width: $sp_cont;
        margin-bottom: 3rem;
      }
    }

    &-rl {
      @include lt-tblt {
        justify-content: flex-end;
      }

      .brandsBox-cont {
        @include lt-tblt {
          order: -1;
        }
      }

      .brandsBox_img {
        @include st-tblt {
          margin-left: auto;
        }
      }
    }

    &-lr {}

    &-cont {
      &_ttl {
        font-size: 3rem;
        font-weight: 500;
        line-height: 1.25;
        margin-bottom: 3rem;
      }

      &_txt {
        line-height: 2;
        margin-bottom: 4rem;
      }
    }
  }
}

/* contact
-----------------------------*/

.topContact {

  padding: 5rem 0 0;
  @include fluid_space($s:'padding', $v:13rem);
  background: $gray;

  @include min-screen( 1301px ){
    width: 100vw;
    margin-left: calc( ( 100% - 100vw ) * .5 );
    margin-right: calc( ( 100% - 100vw ) * .5 );
  };

  &_ttl {
    margin-bottom: 7rem;
  }

  &-list {
    @include maw($v:$pc_Width_m);
    @include block-2col($mr:6rem);

    @include st-tblt {
      width: 80%;
    }

    a {
      display: block;
      border-top: 5px solid #000;
      border-bottom: 5px solid #000;
      @include hover01;
    }

    img {
      width: 100%;
      vertical-align: bottom;
    }
  }
}

/*******************************

    foot

*******************************/


.foot {
  &-top {

    background: $black;

    &-inner {

      @include maw(1240px);
      display: flex;

      padding-top: 5rem;
      padding-bottom: 5rem;

      @include st-tblt {
        flex-direction: column;
        padding-top: 3rem;
        padding-bottom: 3rem;
      }

      >div {
        @include lt-tblt {
          width: 50%;
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
      }
    }
  }

  &-l {
    @include lt-tblt {
      border-right: 1px solid $white;
    }

    @include st-tblt {
      margin-bottom: 4rem;
    }
  }

  &-r {
    @include lt-tblt {

      padding-left: 6%;
    }
  }

  &-add {
    color: $white;

    a {
      color: $white;
      @include hover01;
    }

    &:not(:last-of-type) {
      margin-bottom: 6rem;

      @include st-tblt {
        margin-bottom: 3rem;
      }
    }

    &_ttl {
      font-size: $fz_m;
      font-weight: 700;
      margin-bottom: 1rem;

      span {
        line-height: 1.5;
        margin-top: 1.5rem;
        display: block;
        font-size: $fz_s;

        @include st-tblt {
          font-size: 1.4rem;
        }
      }
    }

    &_add {
      line-height: 1.5;
      margin-bottom: 1rem;
      display: flex;
      span{
        &:first-of-type{
          flex: 0 0 auto;
          margin-right: 0.5em;
        }
      }
    }
    &_tel{
      line-height: 1.5;
    }

  }

  &-nav {
    display: flex;

    ul {
      width: 45%;
    }

    li {
      font-size: 1.6rem;

      &:not(:last-of-type) {
        @include lt-tblt {

          margin-bottom: 3rem;
        }
      }

      a {
        @include hover01;
        font-weight: 600;
        color: $white;

        @include st-tblt {
          display: block;
          padding: 1rem 0;
        }
      }
    }
  }

  &-bottom {
    position: relative;
    padding-bottom: 5rem;

    @include st-tblt {
      display: flex;
      flex-direction: column;
      padding-bottom: 2rem;
      padding-top: 1.5rem;
    }
  }

  &-credit {
    margin: 0 auto;
    padding: 0 20rem;

    @include st-tblt {
      padding: 0 3rem;
    }
  }

  &-logo {
    padding: 2.5rem 0 1.5rem;
    text-align: center;

    @include st-tblt {
      width: 18rem;
      margin: 0 auto;
      padding: 3rem 0 2rem;
    }

    a {
      @include hover01;
      display: inline-block;
    }
  }

  &-copy {
    text-align: center;
    font-size: 1.1rem;
    letter-spacing: $ls_200;
    font-weight: 400;

  }

  &-sns {
    @include lt-tblt {

      position: absolute;
      top: 6rem;
      right: 2rem;
    }

    @include st-tblt {
      order: -1;
    }

    &-list {
      display: flex;

      @include st-tblt {
        justify-content: flex-end;
      }

      li {
        margin-right: 3rem;

        @include st-tblt {
          margin: 0 1rem;
          display: flex;
          align-items: center;
        }
      }

      a {
        display: flex;
        align-items: center;
        width: 1.6rem;
        height: 100%;
        @include hover01;

        @include st-tblt {
          width: 2rem;
        }
      }
    }
  }
}

.foot-bnr{
  @include fluid_space($p:'top',$v:7.5rem);
  @include fluid_space($v:12rem);
  padding: 0 2rem;
  p{
    text-align: center;
    &:not(:last-of-type){
      margin-bottom: 6rem;
      @include st-tblt{
        margin-bottom: 3rem;
      }
    }
  }
  a{
    display: inline-block;
    box-shadow: 7px 7px 15px rgba(0,0,0,.37);
    @include hover01;
    @include st-tblt{
      box-shadow: 3px 3px 10px rgba(0,0,0,.37);
    }
  }
}

.foot-fixed-bnr{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background: $black;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  transition: $transition;
  &.is-active{
    visibility: visible;
    opacity: 1;
  }
  p{
    text-align: center;
  }
  img{
    @include st-sp{
      width: 100%;
    }
  }
}


/*******************************

    lowerKv

*******************************/

.lowerKv {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 350px;
  @include lt-tblt {
    height: calc(70vh - #{$pc_menu_Height});
    @include fluid_space;
  }

  @include st-tblt {
    min-height: 200px;
    margin-bottom: 6rem;
      height: 200px;
      width: calc(100% - 2rem);
      margin-left: auto;
      margin-right: auto;
  }


  &_ttl {
    font-size: 6rem;
    letter-spacing: $ls_120;
    color: $white;
    padding-top: 2rem;
    font-weight: 400;
    @include st-tblt{
      font-size: 7vw;
    }
  }
}

.conceptKv {
  background: url(../img/lower-kv01.png) center center no-repeat;
  background-size: contain;
  &_copy {
    position: absolute;
    bottom: 0rem;
    right: 27rem;
    z-index: 1;
  }

}

.brandsKv {
  background: url(../img/lower-kv01.png) center center no-repeat;
  background-size: contain;
  @include lt-tblt{
  margin-bottom: 10rem;
  }


}

.restKv {
  background: url(../img/lower-kv02.png) center center no-repeat;
  background-size: contain;
  @include lt-tblt{
  margin-bottom: 10rem;
  }

}

.companyKv {
  background: url(../img/lower-kv03.png) center center no-repeat;
  background-size: contain;
  @include lt-tblt{
  margin-bottom: 10rem;
  }

}


.storyKv {
  background: url(../img/lower-kv02.png) center center no-repeat;
  background-size: contain;
  @include lt-tblt{
  margin-bottom: 13.5rem;
  }
}

.alterKv {
  background: url(../img/lower-kv03.png) center center no-repeat;
  background-size: contain;
  @include lt-tblt{
  margin-bottom: 10rem;
  }
}

.contactKv {
  background: url(../img/contact-kv.jpg) center center no-repeat;
  background-size: cover;
  justify-content: flex-start;
  @include lt-tblt{
  margin-bottom: 10rem;
  }
  @include st-tblt{

  height: 30vh;
  min-height: 250px;
  width: 100%;
  }


  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 4.6rem;
    background: $white;
    z-index: 1;
    @include st-tblt {
      display: none;
    }
  }

  &_txt {
    position: absolute;
    left: 0;
    bottom: 6rem;
    padding: 3.5rem 13% 3.5rem 19%;
    line-height: 2;
    font-size: 1.6rem;
    background: rgba($black, .8);
    color: $white;
    @include st-tblt{
      padding: 1.5rem 5% 1.5rem 10%;
    }

  }

  &_ttl {
    padding-left: 19%;
    padding-top: 0;
    padding-bottom: 15rem;
    width: 100%;
    @include st-tblt {
      font-size: 4rem;
      padding-bottom: 13rem;
      padding-left: 10%;
    }

    span {
      display: block;
      font-size: 3rem;
      margin-top: 5rem;
      @include st-tblt {
        margin-top: 2rem;
        font-size: 2rem;
      }
    }
  }
}
.newsKv {
  background: url(../img/news-kv.jpg) center center no-repeat;
  background-size: cover;
  justify-content: flex-start;
  @include lt-tblt{
  margin-bottom: 9rem;
  }
  @include st-tblt{

    height: 30vh;
    min-height: 250px;
    width: 100%;
    }

  &::after {
    @extend .contactKv::after;
  }

  &_ttl {
    @extend .contactKv_ttl;
    span {
      display: block;
      font-size: 3rem;
      margin-top: 5rem;
      @include st-tblt {
        margin-top: 2rem;
        font-size: 2rem;
      }
    }
    @include st-tblt{
      padding-bottom: 0;
    }
  }
}

/*******************************

    concept

*******************************/

.conceptKv{
  margin-bottom: calc(20vw - 6rem);
  &_copy{
    @include st-tblt{
      bottom: -20vw;
      right: 6.5vw;
      width: 20vw;
    }
  }
}
.conceptMain {
  &-inner {
    @include lt-tblt{

    background: url(../img/concept-bg-l.png) left top 12rem no-repeat,
    url(../img/concept-bg-r.png) top 3.5rem right no-repeat;
  background-size: 382px auto, 228px auto;
  @include fluid_space($s:'padding', $v:19rem);
    }
  }
}

.conceptBox {
  display: flex;
  @include lt-tblt{

  @include maw(50%);
  }
  @include st-tblt{
    padding: 0 4rem;
    flex-direction: column;
  }

  &-inner {
    display: flex;
    align-items: flex-start;
    position: relative;
    justify-content: space-between;
  }

  &-cont {
    flex: 0 0 auto;
    z-index: 1;

    &_ttl {
      @include lt-tblt {
        width: 100%;
      }

      font-size: 5rem;
      max-width: 45rem;
      margin: 0 0 5rem auto;
      text-align: left;
      font-weight: 500;
    }

    &_copy {
      @include topCopy();
      font-size: 2rem;
      //margin-left: -.35em;
      margin-bottom: 2.5rem;
      writing-mode: inherit;
      margin-left: 0;
    }

    &_txt {
      margin-bottom: 3rem;
      line-height: 2;
    }
  }

  &_img {
    //max-width: 902px;
    width: 100%;
    z-index: -1;
    @include st-tblt{
      width: 80%;
      margin: 0 auto 3rem;
    }

    img {
      width: 100%;
    }
  }
}

.conceptBox01 {
  @include lt-tblt{

  margin-bottom: 15rem;
  }
  @include st-tblt{
    padding-top: 10rem;
    padding-bottom: 15rem;
    background: url(../img/concept-parts01.png)left top no-repeat,url(../img/concept-parts02.png)right bottom no-repeat;
    background-size: 6rem auto, 12rem auto;
  }
  .conceptBox {
    &-cont {
      @include lt-tblt{

      order: -1;

      width: 45%;
      padding-top: 3rem;
      padding-left: 5%;
      }

      &_copy {}
    }

    &_img {
      @include lt-tblt{

      margin-left: -10%;
      }
    }
  }
}

.conceptBox02 {
  @include lt-tblt{

  margin-bottom: 12rem;
  }
  @include st-tblt{
    padding-bottom: 15rem;
    background: url(../img/concept-parts03.png) left bottom 1rem no-repeat;
    background-size: 10rem auto;
  }

  .conceptBox {
    &-cont {
      position: relative;
      padding-right: 5rem;
      @include lt-tblt{

      margin-top: 8rem;

      }
      &_ttl {
        @include tategaki;
        position: absolute;
        top: 0;
        right: -1rem;
        @include st-tblt{
          right: 1rem;
        }
      }

      &_copy {
        padding-top: 8rem;
        @include st-tblt{
          padding-top: 3rem;
        }
      }
    }

    &_img {
      @include lt-tblt{

      margin-right: 3%;
      margin-left: -5%;
      }
    }
  }
}

.conceptBox03 {
  @include lt-tblt{

  margin-bottom: 12rem;
  }

  @include st-tblt{
    padding-bottom: 12rem;
    background: url(../img/concept-parts04.png) right bottom 1rem no-repeat;
    background-size: 15rem auto;
  }
  .conceptBox {
    &-cont {
      @include lt-tblt{

      order: -1;

      width: 45%;
      padding-top: 3rem;
      padding-left: 3%;

      }
      &_copy {}
    }

    &_img {
      @include lt-tblt{

      padding-top: 3rem;
      margin-left: -15%;
      margin-right: -5%;
      }
    }
  }
}

.conceptBox04 {

  .conceptBox {
    position: relative;
    &-cont {
      @include lt-tblt{

        padding-top: 1rem;
        padding-left: 3%;
        margin-right: -3%;
      }
      &_ttl {}

      &_copy {}
    }

    &_img {
      @include lt-tblt{
      margin-left: -7%;
      }
      @include st-tblt{
        margin-left: -1%;
      }
    }
  }

}



/*******************************

    brands

*******************************/


@mixin brands($v:'brands') {
  .#{$v}Box {
    &:not(:last-of-type) {}

    &-head {
      img {

        width: 92%;

        @include st-tblt {
          width: 100%;
        }
      }
    }

    &-main {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @include st-tblt {
        flex-direction: column;
      }

      figure {
        margin-top: -7rem;
        width: 46%;
        box-shadow: -15px 20px 30px rgba($black, .3);

        @include st-tblt {
          width: 50%;
          order: -1;
          z-index: 1;
          box-shadow: -7px 10px 15px rgba($black, .3);
        }

        img {
          vertical-align: bottom;
        }
      }
    }

    &-cont {
      width: 50%;
      padding: 5rem 18% 17rem 8%;
      background: $gray02;

      @include st-tblt {
        width: 100%;
        position: relative;
        margin-top: -3rem;
        padding: 5rem 10% 13rem 10%;
      }

      &_ttl {
        font-size: 4rem;
        line-height: 1.2;
        margin-bottom: 3.5rem;

        @include st-tblt {
          margin-top: 4rem;
        }
      }

      &_txt {
        line-height: 2;
        margin-bottom: 3rem;
      }
    }

    &:nth-of-type(even) {

      .#{$v}Box {
        &-head {

          text-align: right;
        }

        &-main {
          figure {
            order: -1;
          }
        }
      }
    }

    &:nth-of-type(odd) {
      .#{$v}Box {
        &-main {
          figure {
            box-shadow: 15px 20px 30px rgba($black, .3);

            @include st-tblt {
              box-shadow: 7px 10px 15px rgba($black, .3);
            }
          }
        }

        &-cont {
          @include lt-tblt {
            padding-right: 8%;
            padding-left: 18%;
          }
        }
      }
    }
  }
}

.brands {

  @include brands();

}




/*******************************

    restaurant

*******************************/

.rest {
  @include brands('rest');

  .restBox {
    @include fluid_space($v:27rem);
    &_ttl {
      text-align: center;
      font-size: 3rem;
      margin-bottom: 5rem;
      font-weight: 600;
      letter-spacing: $ls_120;
      padding-left: 6rem;

      @include st-tblt {
        padding-left: 0;
      }
    }

    &-main{
      @include fluid_space($v:9rem);
      position: relative;
      z-index: 1;
    }
    &-head {
      position: relative;
      text-align: right;
      .txt{
        position: absolute;
        font-size: 1.8rem;
        letter-spacing: .12em;
        top: 4rem;
        right: 4rem;
        text-align: left;
        line-height: 1.2;
        @include st-tblt{
          top: 1.5rem;
          right: calc(1.5rem + 5px);
          font-size: 3vw;
        }
      }
      .thumb{
        position: absolute;
        bottom: 3rem;
        right: 4rem;
        width: 16.2rem;
        @include st-tblt{
          width: 20vw;
          max-width: 16.2rem;
          bottom: 1rem;
          right: calc(1rem + 5px);
        }
      }
      .line{
        width: 15px;
        position: absolute;
        height: 100%;
        right: 0;
        top: 0;
        background: #000;
        @include st-tblt{
          width: 5px;
        }
      }
      &_img {


      }
    }

    &-cont {
      @include lt-tblt {
        padding-top: 10rem;
        padding-bottom: 5rem;
      }

      @include st-tblt {
        width: 100%;
        position: relative;
        margin-top: -3rem;
        padding: 5rem 10%;
      }

      &_ttl {

        span {
          display: block;
          font-size: 2rem;
          margin-bottom: 1rem;
        }
      }
      &_txt{
        margin-bottom: 3.5rem;
        font-size: 1.4rem;
      }
      &_add{
        font-size: 1.5rem;
        margin-bottom: 2rem;
        line-height: 1.75;
      }
    }
  }

  .restBox:nth-of-type(odd) {
    .restBox {
      &-head {
        .txt{
          color: #7e2618;
        }
        .line{
          background: #7e2618;
        }
        .thumb{
          @include lt-tblt{

        bottom: 10rem;
          }
        }
        &_img {

        background: $gray02;
        }
      }
    }
  }

  .restBox:nth-of-type(even) {
    .restBox {
      &-head{

        .txt{
          color: #435318;
        }
        .line{
          background: #435318;
        }
      }
      &-main {
        figure {

          box-shadow: 15px 20px 30px rgba($black, .3);

          @include st-tblt {
            box-shadow: 7px 10px 15px rgba($black, .3);
          }
        }
      }

      &-cont {}
    }
  }

  .restBox02 {
    .restBox-main{
      @include fluid_space($v:14rem);
    }
  }

  .restInfo{
    @include maw(880px);
    &_ttl{
      font-size: 2rem;
      margin-bottom: 6rem;
      text-align: center;
      font-weight: 700;
      @include st-tblt{
        margin-bottom: 3rem;
      }
    }
    &_txt{
      text-align: center;
      margin-top: -2rem;
      line-height: 1.5;
      margin-bottom: 6rem;
      @include st-tblt{
        margin-top: -1rem;
        margin-bottom: 3rem;
      }
    }
    &-list{
      @include block-2col($mr:4rem,$mt:10rem,$spcol:'2',$spmt:2rem);
      + .restInfo_ttl{
        @include fluid_space($p:'top',$v:20rem);
      }
      li{
        text-align: center;
      }
      &_ttl{
        font-size: 2rem;
        margin-top: 1rem;
        line-height: 2;
        &.bento{
          font-size: 1.5rem;
        }
      }
      &_txt{
        margin-top: 2rem;
        line-height: 2;
        &.bento{
          font-size: 1.25rem;
        }
      }
    }
    &-gyoza{
      @include block-4col($mr:3.5rem,$mt:8rem,$spcol:'2');
      + .restInfo_ttl{
        @include fluid_space($p:'top',$v:15rem);
      }
      li{
        text-align: center;
      }
      &_ttl{
        margin-top: 2rem;
        font-size: 1.5rem;
        line-height: 2;
        @include st-tblt{
          margin-top: 1rem;
        }
      }
      &_txt{
        font-size: 1.25rem;
        margin-top: 2rem;
        line-height: 2;
        @include st-tblt{
          margin-top: 1rem;
        }
      }
    }
    &-souzai{

      @include fluid_space($p:'top',$v:15rem);
      @include maw-fix(880px);
      &-list{
        margin-bottom: 5rem;
        @include lt-tblt{
        @include block-3col($mr:4.5rem);
        }
        @include st-tblt{
          margin-bottom: 2rem;
          display: flex;
          justify-content: space-between;
        }
        li{
          @include st-tblt{
            width: calc(33.333% - (2rem / 3));
            img{
              width: 100%;
            }
          }
        }
      }
      .txt{
        text-align: center;
        line-height: 2;
        letter-spacing: .12em;
      }
    }
    &-orde{

      @include fluid_space($p:'top',$v:10rem);
      &-img{
        margin-bottom: 5rem;
        @include st-tblt{
          margin-bottom: 2rem;
        }
        text-align: center;
        img{
          vertical-align: bottom;
        }
      }
      .txt{
        text-align: center;
        line-height: 2;
        letter-spacing: .12em;
      }
    }
  }


}




/*******************************

    company

*******************************/

.company {

  .companyHead {
    position: relative;

    @include lt-tblt {}

    @include fluid_space($s:'padding', $v:11rem);

    &_ttl {
      position: absolute;
      right: 7%;
      top: 5rem;
      font-weight: 600;
      letter-spacing: .24em;
      line-height: 2;
      white-space: nowrap;

      @include lt-tblt {
        @include tategaki;
      }

      @include st-tblt {
        width: 100%;
        position: relative;
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 3rem;
        right: 0;
        top: 0;
      }
    }

    &-cont {
      width: 81%;
      position: relative;

      @include st-tblt {
        width: 100%;
      }

      &_img {
        @include st-tblt {
          width: 100%;
        }
      }

      &_txt {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 38rem;
        line-height: 2.5;
        padding: 4rem 5rem 0;
        background: $white;

        @include st-tblt {
          width: 80%;
          padding: 2.5rem 3rem;
          position: relative;
          margin: 0 auto;
          justify-content: center;
          margin-top: -2.5rem;
        }
      }
    }
  }

  .companyMain {

    @include maw($pc_Width_m);

    &_ttl {
      font-size: 5rem;
      font-weight: 600;
      letter-spacing: .1em;
      margin-bottom: 5rem;

      @include st-tblt {
        text-align: center;
      }
    }
  }

  .companyInfo {
    @include fluid_space;

    &_ttl {
      font-size: 3rem;
      letter-spacing: $ls_120;
      font-weight: 600;
      margin-bottom: 3rem;

      @include st-tblt {
        text-align: center;
      }
    }

    &-list {
      div {
        display: flex;
        padding: 3rem 0;
        font-size: 1.5rem;
        line-height: 1.5;
        border-bottom: 1px solid #000;
      }

      dt {
        width: 16rem;

        @include st-tblt {
          width: 9rem;
        }
      }

      dd {
        @include st-tblt {
          margin-left: 2rem;
          text-align: left;
        }
      }
    }
  }

  .companyContact {
    padding-bottom: 6rem;
    @include st-tblt{
      padding-bottom: 3rem;
    }
    &_ttl {
      @extend .companyInfo_ttl;
    }

    &_txt {
      max-width: 57rem;
      line-height: 2;
      margin-bottom: 6rem;

      @include st-tblt {
        text-align: center;
        margin-bottom: 4rem;
      }
    }

    &-list {
      @include block-2col($mr:6rem);

      @include st-tblt {
        width: 80%;
        margin: 0 auto;
      }

      a {
        @include hover01;
      }
    }
  }
}

/*******************************

    contact

*******************************/

.contactMain {
  @include maw(880px);


  &-inner {
    display: flex;

    @include st-tblt {
      flex-direction: column;
    }
  }
}

.contactInfo {
  width: 50%;
  padding-right: 5%;
  flex: 0 0 auto;

  @include st-tblt {
    width: 90%;
    padding-left: 15%;
  }

  &-shop {
    margin-bottom: 8rem;

    @include st-tblt {
      text-align: center;
    }

    &_ttl {
      font-size: 3rem;
      letter-spacing: $ls_200;
      margin-bottom: 7.5rem;
      @include st-tblt{
        margin-bottom: 4rem;
      }
    }

    &-add {
      &:not(:last-of-type) {
        margin-bottom: 6rem;
        @include st-tblt{
          margin-bottom: 4rem;
        }
      }

      &_ttl {
        font-size: 2rem;
        margin-bottom: 1.5rem;

        span {
          display: block;
          font-size: 1.4rem;
          letter-spacing: 0em;
          margin-top: 2rem;
        }
      }

      &_add {
        line-height: 1.5;
        margin-bottom: .5rem;
      }
    }
  }

  &-recruit {
    @include st-tblt {
      margin-bottom: 4rem;
    }

    &_ttl {
      @extend .contactInfo-shop_ttl;
      margin-bottom: 6rem;

      @include st-tblt {
        margin-bottom:4rem;
        text-align: center;
      }
    }

    li {
      margin-bottom: 2.5rem;

      @include st-tblt {
        text-align: center;
      }
    }

    a {
      min-width: 18rem;
      justify-content: center;
    }
    .ttl{
      margin-bottom: 1.5rem;
    }
  }
}

.contactForm {
  width: 100%;

  @include st-tblt {
    order: -1;
    margin-bottom: 5rem;
  }

  dl {
    margin-bottom: 3.5rem;
  }

  input[type="text"],
  input[type="email"],
  textarea,
  select {
    @include font_Serif;
    font-size: $fz_cmn;
    padding: 2.75rem 0;
    line-height: 1.5;
    width: 100%;
    @include st-tblt{
      font-size: 16px;
    }
  }

  input[type="text"]::placeholder,
  input[type="email"]::placeholder,
  textarea::placeholder {
    color: $black;
  }

  select {
    display: block;
  }

  .box:first-of-type {
    margin-top: .5rem;
    border-top: 1px solid #000;
    position: relative;

    &::after {
      position: absolute;
      right: 15px;
      display: block;
      content: "";
      width: 0;
      height: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Safari用 */
      transform: translateY(-50%);
      border-style: solid;
      border-width: 12px 7px 0 7px;
      border-color: #333333 transparent transparent transparent;
    }
  }

  .box {
    border-bottom: 1px solid #000;
  }

  input[type="submit"] {
    @include font_Serif;
    font-size: 1.6rem;
    padding: 3rem 0;
    text-align: center;
    width: 100%;
    letter-spacing: $ls_120;
    color: $white;
    font-weight: 700;
    background: $black;
    border-radius: 1rem;
  }

  .send-box {
    margin-bottom: 4rem;
  }

  &_note {
    font-size: 1.2rem;
    margin-bottom: 3rem;
  }
}



/*******************************

    story

*******************************/

.story {
  .storyMain {

    &_ttl {
      flex: 0 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;

      &::before,
      &::after {
        content: "";
        display: block;
        height: 16px;
        width: 1px;
        background: $black;
        margin-top: 2rem;
      }

      &-wrap {
        display: flex;
        align-items: center;

        &::before,
        &::after {
          content: "";
          display: block;
          height: 1px;
          width: 100%;
          background: $black;
          margin-top: 2rem;
        }
      }

      span {
        text-align: center;
        letter-spacing: $ls_200;
        padding: 0 4.5rem;

        @include st-tblt {}
      }

      strong {
        display: block;
        font-size: 4.5rem;
        font-weight: 500;
        margin-top: 0.5rem;

        @include st-tblt {
          font-size: 3rem;
        }
      }
    }
  }

  .storyBox {
    @include fluid_space($s:'padding', $v:12.5rem);

    &:first-of-type {
      @include st-tblt {
        margin-top: -12px;
      }

      @include lt-tblt {
        margin-top: -2.2rem;
        padding-top: 9.5rem;
      }
    }

    &:nth-of-type(n+2) {
      padding-top: 4rem;
    }

    &-head {
      position: relative;
      @include fluid_space($v:10rem);

      &_copy {
        align-items: center;
        font-size: $fz_m;
        display: flex;
        letter-spacing: .24em;
        line-height: 2;
        font-weight: 600;

        @include lt-tblt {
          @include tategaki;

        position: absolute;
        right: 6%;
        top: 6rem;
        white-space: nowrap;
        }

        @include st-tblt {
          position: relative;
          flex-direction: column;
          order: 1;
          right: 0%;
          text-align: center;
          margin-bottom: 6rem;
        }

        i {
          margin-bottom: 3rem;

          @include st-tblt {
            order: -1;
            width: 9rem;
            margin-bottom: 2rem;
          }
        }
      }

      &_img {
        width: 81%;
        position: relative;

        @include st-tblt {
          width: 100%;
          margin-bottom: 3rem;

        }

      }
    }


    &-cont {
      @include maw($pc_Width_m);
      display: flex;

      @include st-tblt {
        display: flex;
        flex-direction: column;
        margin-left: 0rem;
      }

      &-inner {
        &:first-of-type {
          @include lt-tblt {
            width: 44%;
            flex: 0 0 auto;
          }

          @include st-tblt {
            width: 100%;
          }
        }
      }

      &_ttl {
        margin-bottom: 2rem;
        font-size: $fz_s;
      }

      &_copy {
        font-size: $fz_l;
        line-height: 1.7;
        letter-spacing: .025em;
        @include st-tblt{
          margin-bottom: 3rem;
        }

        span {
          display: block;
          font-size: $fz_s;
          margin-top: 1rem;
          line-height: 1.8;
        }
      }

      &_txtbox {
        margin-bottom: 4rem;

        p {
          line-height: 2.2;

        }

        p:not(:last-of-type) {
          margin-bottom: 4rem;
        }
      }

      &_poem {
        display: flex;
        align-items: flex-start;
        line-height: 2.2;
        flex: 0 0 auto;
        font-weight: 600;

        @include lt-tblt {
          white-space: nowrap;
        }

        &::before {
          content: "";
          display: block;
          width: 5rem;
          height: 1px;
          background: $black;
          margin-top: 1em;
          margin-right: 1rem;
        }
      }

      &_more {
        margin-top: 5rem;

        @include st-tblt {
        }
      }
    }


    &:nth-of-type(odd) {
      .storyBox-head {
        &_img {
          @include lt-tblt{

          margin: 0 0 0 auto;
          }
        }

        &_copy {
          @include lt-tblt {
            right: inherit;
            left: 6%;
          }
        }
      }
    }
  }

  .storyBox-o {
    background: #fdfaf8;
    background: -webkit-linear-gradient(-45deg, #fdfaf8 0%, #d5a47a 100%);
    background: linear-gradient(135deg, #fdfaf8 0%, #d5a47a 100%);
  }

  .storyBox-p {
    background: #ffffff;
    background: -webkit-linear-gradient(-45deg, #ffffff 10%, #cd837b 100%);
    background: linear-gradient(135deg, #ffffff 10%, #cd837b 100%);
  }

  .storyBox-g {

    background: #ffffff;
    background: -webkit-linear-gradient(left, #ffffff 1%, #b9c8aa 100%);
    background: linear-gradient(to right, #ffffff 1%, #b9c8aa 100%);

    @include st-tblt {
      padding-top: 7rem;
    }
  }

}

/*******************************

    ARCHIVE - NEWS,ALT

*******************************/

.arcMain {
  @include fluid_space($v:10rem);

  &-list {
    @include maw($pc_Width_m);
    @include fluid_space($p:'bottom', $v:15rem);

    @include block-2col($mr:6rem, $mt:10rem, $spcol:'1', $spmt:3rem);

    li {}

    figure {
      @include trim();
      background: $white;
      @include trim-size($v:58%);
      overflow: hidden;
    }

    a {
      @include block-a;
      @include hover01;
    }

    span {
      display: block;
    }

    &-cont {
      position: relative;
      margin-top: -3rem;
      padding: 4rem 3.5rem 3.5rem;
      width: 75%;
      margin: 0 -1rem 0 auto;
      background: $white;
      box-shadow: -5px 5px 20px rgba($black, .3);

      @include st-tblt {}

      &_cat {
        font-size: 1.5rem;
        position: absolute;
        top: 0;
        left: 0;
        letter-spacing: .2em;
        padding: .5rem 1.25rem;
        background: $black;
        color: $white;

        &.cat-info,
        &.cat-wine {
          background: #BA5349;
        }

        &.cat-event,
        &.cat-party {
          background: #526B2A;
        }

        &.cat-dinner {
          background: #be7333;
        }
      }

      &_ttl {
        line-height: 1.5;
        font-size: 1.6rem;
      }

      &_day {
        margin-top: 1.5rem;
        font-size: $fz_s;
      }
    }
  }

  .pager {
    .nav-links {
      display: flex;
      justify-content: center;
      align-items: center;

      a,
      span {
        margin: 0 1.25rem;
        padding: 0 0 .5rem;
      }

      .current {
        border-bottom: 1px solid #000;
      }

      a {
        @include hover01;
      }
    }
  }
}

.newsMain {


  &_ttl {
    @include lt-tblt{

    margin-bottom: 8rem;
    }
  }
}

.alterMain {
  &_ttl {
    text-align: center;
    font-size: 3rem;
    font-weight: 500;
    @include fluid_space($v:10rem);
  }

  &-cat-list {
    @include maw(705px);
    margin: 0 auto;
    @include fluid_space($v:8rem);
    @include block-4col($mr:3.5rem, $spcol:'2');

    li {

      min-width: 15rem;
      @include cmn_btn01();

      a {
        width: 100%;
        justify-content: space-between;
        text-align: center;
        &::after {
          flex: 0 0 auto;
        }
      }

      span {
        display: block;
        text-align: center;
        width: 100%;
      }
      &.is-active{
        a{
          opacity: 0.4;
          pointer-events: none;
        }
      }
    }
  }

  .arcMain-list-cont_ttl {
    padding-top: 1rem;
    font-size: 1.4rem;
  }
}


/*******************************

    SINGLE - NEWS,ALT

*******************************/

.singleMain {
  @include maw($pc_Width_m);
  @include fluid_space($v:8rem);

  .pankuzu {
    margin-bottom: 4.5rem;
  }

  &_cat {
    font-size: 1.5rem;
    letter-spacing: .2em;
    padding: .5rem 1.25rem;
    background: $black;
    color: $white;
    display: inline-flex;
    margin-bottom: 3rem;

    &.cat-info,
    &.cat-wine {
      background: #BA5349;
    }

    &.cat-event,
    &.cat-party {
      background: #526B2A;
    }

    &.cat-dinner {
      background: #be7333;
    }
  }

  &_day {
    font-size: $fz_s;
    margin-bottom: 1.5rem;
  }

  &_ttl {
    line-height: 1.5;
    font-size: 3rem;
    margin-bottom: 5rem;
  }

  &-cont {
    @include fluid_space($v:8rem);
    @include st-tblt{
      margin-bottom: 6rem;
    }

    a{
      text-decoration: underline;
    }
    strong,
    b{
      font-weight: 900;
    }
    >img {
      width: 100%;
      display: block;
    }

    >img+h3,
    >img+div,
    >img+ul,
    >img+img,
    >ul+h3,
    >ul+div,
    >ul+ul,
    >ul+img,
    >div:not(.info)+h3,
    >div:not(.info)+div,
    >div:not(.info)+ul,
    >div:not(.info)+img {
      margin-top: 5rem;

      @include st-tblt {
        margin-top: 3rem;
      }
    }

    .flow{
      max-width: 840px;
      margin-left: auto;
      margin-right: auto;
      padding: 4.5rem;
      border: 1px solid $black;
      @include st-tblt{
        padding: 2rem;
      }
      &-ttl{
        line-height: 1.5;
        padding-bottom: 0.25rem;
        margin-bottom: 1.75rem;
        border-bottom: 1px solid $black;
      }

      ul{
        display: block;
      }
      li{
        line-height: 1.5;
        &:not(:last-of-type){
          margin-bottom: 1.5rem;
        }
      }
    }
    .ttl {
      line-height: 2.25;
      font-size: 2rem;
      margin-bottom: 3rem;
    }

    div {
      line-height: 2.25;
    }

    ul:not(.flow-list) {
      display: flex;
      justify-content: space-between;

      li {
        width: 48.5%;
      }
    }

    .info {
      background: #f6ebe2;
      padding: 3rem;

      h3 {
        font-size: 1.2rem;
        color: #be7333;
      }
    }
  }

  &-postbtn {
    @include maw(750px);
    display: flex;
    justify-content: space-between;

    @include st-tblt {
      flex-direction: column;
      align-items: center;
    }

    &_prev,
    &_next {
      @include st-tblt {
        margin-bottom: 1.5rem;
      }

      a {
        width: 200px;
        display: flex;
        align-items: center;

        @include st-tblt {
          width: 100%;
          min-height: 2.3rem;
          justify-content: center;
        }

      }

      i {

        display: block;
        width: 2.3rem;
        height: 2.3rem;
        flex: 0 0 auto;
      }
    }

    &_prev {
      @include st-tblt {
        order: 0;
      }

      i {
        background: url(../img/icon-prev.svg) center center no-repeat;
        background-size: 2.3rem auto;
        margin-right: 0.5rem;
      }
      span{
        @include lt-tblt{
          width: 200px;
          display: block;
        }
      }
    }

    &_next {
      @include st-tblt {
        order: 1;
      }
      a{
        justify-content: flex-end;
      }
      i {
        background: url(../img/icon-next.svg) center center no-repeat;
        background-size: 2.3rem auto;
        margin-left: 0.5em;
      }
    }

    &_back {
      @include cmn_btn01();

      @include st-tblt {
        order: 2;
        margin-top: 2rem;
      }

      a {

        color: $black;
        border: 1px solid #000;
        background: $white;

        &::after {
          display: none;
        }
      }
    }
  }
}



/*******************************

    js

*******************************/

.vbox-container img {
  @include lt-tblt {

    max-height: calc(90vh - 40px);
  }
}

.vbox-close {
  right: inherit;
  left: 0;
}


/*******************************

     ANIMATION

*******************************/

.f01 {
  text-align: center;
  transition: all .5s cubic-bezier(.43, .13, .56, .72);
  transform: scale(.5);
  transform: translateY(1rem);
  opacity: 0;

  //margin-bottom: 100px;
  &.frame-in {
    transform: scale(1);
    opacity: 1;
    transform: translateY(0);
  }
}

.f02 {
  position: relative;

  &::after {
    transition: all .75s cubic-bezier(.43, .13, .56, .72);
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: $beige;
    transform-origin: left bottom;
    transform: scaleX(1);
  }

  &.frame-in {
    &::after {
      width: 0;
      transform: scaleX(0);
    }
  }
}

.f05 {
  transition: all 1.75s cubic-bezier(.43, .13, .56, .72);
  position: relative;
  opacity: 0;

  &::after {
    transition: all 1.5s ease-out;
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: $beige;
    transform-origin: left bottom;
    transform: scaleX(1);
  }

  &.frame-in {
    opacity: 1;

    &::after {
      width: 0;
      transform: scaleX(0);
    }
  }
}


.f03 {
  transition: opacity .5s cubic-bezier(.43, .13, .56, .72);
  opacity: 0;

  &.frame-in {
    opacity: 1;
  }
}

.f03-2 {
  transition: opacity .5s cubic-bezier(.43, .13, .56, .72) .5s;
  opacity: 0;

  &.frame-in {
    opacity: 1;
  }
}

.f04 {
  position: relative;

  &::after {
    content: "";
    display: block;
    background: $lemon;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: transform .5s cubic-bezier(.43, .13, .56, .72);
    transform-origin: left bottom;
    transform: scaleX(1);
  }

  p {
    opacity: 0;
    transition: opacity .5s ease .5s;
  }

  &.frame-in {
    &::after {
      width: 0;
      transform: scaleX(0);
    }

    p {
      opacity: 1;
    }
  }
}

.f01:last-of-type {
  //margin-bottom: 0;
}





@import "head";


@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video, main {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, main {
  display: block;
}

body, html {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:after, blockquote:before {
  content: '';
  content: none;
}

q:after, q:before {
  content: '';
  content: none;
}

a img {
  border: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* iOSでのデフォルトスタイルをリセット */
input[type="button"], input[type="text"], input[type="tel"], input[type="email"], input[type="submit"], input[type="image"], textarea {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
}

input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
  display: none;
}

input[type="submit"]::focus,
input[type="button"]::focus {
  outline-offset: -2px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
}

button,
input[type="submit"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
}

input[type="radio"] {
  display: none;
}

input[type="radio"]:checked + label {
  background: #5f5f5f;
}

select::-ms-expand {
  display: none;
}

address {
  font-style: inherit;
}

/* MEDIA QUERY */
@media screen and (max-width: 641px) {
  .lt-sp {
    display: none !important;
  }
}

@media screen and (min-width: 642px) {
  .st-sp {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .lt-tblt {
    display: none !important;
  }
}

@media screen and (min-width: 1025px) {
  .st-tblt {
    display: none !important;
  }
}

/* Text Color */
/* bg */
/* FONT SIZE */
/* FONT SET */
/* xd文字スタイルに入ってるセットを設定 */
/*******************************

   SINGLE

*******************************/
/*******************************

    FONT

*******************************/
/*******************************

    ARROW

*******************************/
/*******************************

    BLOCK
    
*******************************/
/*******************************

    BASE

*******************************/
html,
body {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

html {
  position: relative;
  font-family: "Noto Serif JP", "游明朝", YuMincho, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.12em;
  font-weight: 600;
}

@media screen and (min-width: 1025px) {
  html {
    font-size: 10px;
  }
}

@media screen and (max-width: 1024px) {
  html {
    font-size: calc(10px + 0.15601vw);
  }
}

@media screen and (max-width: 641px) {
  html {
    font-size: 2vw;
  }
}

@media screen and (max-width: 320px) {
  html {
    font-size: 2.35vw;
  }
}

body {
  background: #FFF;
  font-size: 1.45rem;
  color: #000;
  letter-spacing: 0.07em;
}

body.fixed {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
}

img {
  max-width: 100%;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

a {
  text-decoration: none;
  color: #000;
}

p {
  word-wrap: break-word;
}

p img {
  vertical-align: bottom;
  max-width: 100%;
}

/* DISPLAY
------------------------------*/
@media screen and (min-width: 769px) {
  .lt-tbminNone {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .st-tbminNone {
    display: none !important;
  }
}

@media screen and (min-width: 1025px) {
  .lt-tbltNone {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .st-tbltNone {
    display: none !important;
  }
}

@media screen and (min-width: 642px) {
  .lt-spNone {
    display: none !important;
  }
}

@media screen and (max-width: 641px) {
  .st-spNone {
    display: none !important;
  }
}

@media screen and (min-width: 642px) and (max-width: 1024px) {
  .tbltNone {
    display: none !important;
  }
}

[class$="_ttl"],
[class*="_ttl "] {
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 1025px) {
  img[src$="_sp.svg"],
  img[src$="_sp.jpg"],
  img[src$="_sp.png"] {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  img[src$="_pc.svg"],
  img[src$="_pc.jpg"],
  img[src$="_pc.png"] {
    display: none;
  }
}

@media screen and (min-width: 642px) and (max-width: 1024px) and (max-width: 1024px) {
  img[src$="_pc.svg"]:not(.tbon),
  img[src$="_pc.jpg"]:not(.tbon),
  img[src$="_pc.png"]:not(.tbon) {
    display: none;
  }
}

@media screen and (min-width: 642px) and (max-width: 1024px) and (max-width: 1024px) {
  img[src$="_sp.svg"].tbnone,
  img[src$="_sp.jpg"].tbnone,
  img[src$="_sp.png"].tbnone {
    display: none;
  }
}

body {
  position: relative;
}

@media screen and (min-width: 1025px) {
  main {
    margin: 8rem auto 0;
    max-width: 1300px;
  }
}

.cmn_ttl01 {
  width: 80%;
  max-width: 32.5rem;
  border-bottom: 1px solid #000;
  text-align: right;
  font-size: 2.4rem;
  letter-spacing: 0.07em;
  padding-bottom: 1rem;
  font-weight: 600;
}

@media screen and (max-width: 1024px) {
  .cmn_ttl01 {
    width: 50%;
    margin-bottom: 6rem;
  }
}

.cmn_btn01 a {
  background: #000;
  align-items: center;
  display: flex;
  align-items: center;
  display: inline-flex;
  color: #FFF;
  height: 3rem;
  border-radius: 1.5rem;
  padding: 0 1.5rem;
  line-height: 3rem;
  font-weight: 600;
  transition: ease 0.3s;
  font-size: 1.6rem;
  white-space: nowrap;
}

.cmn_btn01 a:after {
  content: "";
  display: block;
  background: url(../img/icon-arrow.svg) center bottom no-repeat;
  width: .5rem;
  height: 1.2rem;
  margin-left: .75rem;
}

.cmn_btn01 a:hover {
  opacity: 0.6;
}

.cmn_btn01-p a {
  background: #000;
  align-items: center;
  display: flex;
  align-items: center;
  display: inline-flex;
  color: #FFF;
  height: 3rem;
  border-radius: 1.5rem;
  padding: 0 1.5rem;
  line-height: 3rem;
  font-weight: 600;
  transition: ease 0.3s;
  font-size: 1.6rem;
  white-space: nowrap;
}

.cmn_btn01-p a:after {
  content: "";
  display: block;
  background: url(../img/icon-arrow.svg) center bottom no-repeat;
  width: .5rem;
  height: 1.2rem;
  margin-left: .75rem;
}

.cmn_btn01-p a:hover {
  opacity: 0.6;
}

.cmn_btn01-p a {
  background: #ba5449;
}

.cmn_btn02-o a {
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  display: inline-flex;
  border: 1px solid #be7333;
  background: #f0e0d2;
  color: #be7333;
  transition: ease 0.3s;
  padding: 1rem 1.25rem;
}

.cmn_btn02-o a:after {
  content: "";
  display: block;
  background: url(../img/icon-link-o.svg) center bottom no-repeat;
  width: 1.8rem;
  height: 1.4rem;
  margin-left: .75rem;
}

.cmn_btn02-o a:hover {
  opacity: 0.6;
}

.cmn_btn02-g a {
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  display: inline-flex;
  border: 1px solid #526b2a;
  background: #c0d3b1;
  color: #526b2a;
  transition: ease 0.3s;
  padding: 1rem 1.25rem;
}

.cmn_btn02-g a:after {
  content: "";
  display: block;
  background: url(../img/icon-link-g.svg) center bottom no-repeat;
  width: 1.8rem;
  height: 1.4rem;
  margin-left: .75rem;
}

.cmn_btn02-g a:hover {
  opacity: 0.6;
}

article {
  position: relative;
}

/*******************************

    topKv

*******************************/
/* kv
-----------------------------*/
.topKv {
  position: relative;
}

@media screen and (min-width: 642px) {
  .topKv {
    margin-bottom: 11rem;
  }
}

@media screen and (max-width: 1024px) {
  .topKv {
    margin-bottom: calc(11rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .topKv {
    margin-bottom: calc(11rem * .5);
  }
}

@media screen and (min-width: 1025px) {
  .topKv {
    min-height: 630px;
    height: calc(100vh - 8rem);
  }
}

@media screen and (max-width: 1024px) {
  .topKv {
    min-height: 500px;
  }
}

.topKv-wrap {
  height: 100%;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 642px) {
  .topKv-wrap {
    margin-bottom: 11rem;
  }
}

@media screen and (max-width: 1024px) {
  .topKv-wrap {
    margin-bottom: calc(11rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .topKv-wrap {
    margin-bottom: calc(11rem * .5);
  }
}

@media screen and (min-width: 1025px) {
  .topKv-wrap {
    min-height: 630px;
    height: calc(100vh - 8rem);
  }
}

@media screen and (max-width: 1024px) {
  .topKv-wrap {
    min-height: 500px;
  }
}

.topKv-inner {
  height: 100%;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .topKv-inner {
    overflow: hidden;
  }
}

.topKv_ttl {
  position: absolute;
  bottom: 7rem;
  left: 6rem;
  z-index: 1;
}

@media screen and (min-width: 1025px) {
  .topKv_ttl {
    max-width: 40%;
  }
}

@media screen and (max-width: 1024px) {
  .topKv_ttl {
    bottom: 10vw;
    left: 3vw;
    width: 63vw;
  }
}

.topKv_copy {
  position: absolute;
}

.topKv_copy span {
  display: block;
}

.topKv01 .inner::before {
  position: absolute;
  content: "";
  width: 100%;
  height: calc(100% - 6rem);
  background: url(../img/top-kv.jpg) center top no-repeat;
  background-size: contain;
}

@media screen and (max-width: 1024px) {
  .topKv01 .inner::before {
    background: url(../img/top-kv_sp.jpg) center top no-repeat;
    background-size: contain;
  }
}

@media screen and (min-width: 1025px) {
  .topKv01 .copy {
    bottom: 5.5rem;
    right: 14.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .topKv01 .copy {
    right: 6vw;
    top: 70vw;
    width: 20vw;
  }
}

.topKv02 .inner::before {
  position: absolute;
  content: "";
  width: 100%;
  height: calc(100% - 6rem);
  background: url(../img/top-kv02.jpg) center top no-repeat;
  background-size: contain;
}

@media screen and (max-width: 1024px) {
  .topKv02 .inner::before {
    background: url(../img/top-kv02_sp.jpg) center top no-repeat;
    background-size: contain;
  }
}

@media screen and (min-width: 1025px) {
  .topKv02 .copy {
    bottom: 7.5rem;
    right: 14.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .topKv02 .copy {
    right: 6vw;
    top: 78vw;
    width: 20vw;
  }
}

img {
  vertical-align: bottom;
}

.cmn-anchor {
  margin-top: -8rem;
  padding-top: 8rem;
}

@media screen and (max-width: 641px) {
  .cmn-anchor {
    margin-top: -5rem;
    padding-top: 5rem;
  }
}

/* news
-----------------------------*/
@media screen and (min-width: 642px) {
  .topNews {
    margin-bottom: 17.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .topNews {
    margin-bottom: calc(17.5rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .topNews {
    margin-bottom: calc(17.5rem * .5);
  }
}

@media screen and (min-width: 1025px) {
  .topNews_ttl {
    margin-bottom: 6rem;
  }
}

.topNews-cont {
  max-width: calc(880px + 6rem);
  padding: 0 3rem;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1024px) {
  .topNews-cont {
    max-width: calc(880px + 4rem);
    padding: 0 2rem;
  }
}

.topNews-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}

@media screen and (min-width: 1025px) {
  .topNews-list li {
    width: calc(33.333% - ((7rem * 2) / 3));
  }
  .topNews-list li:not(:nth-of-type(3n)) {
    margin-right: 7rem;
  }
  .topNews-list li:nth-of-type(n+4) {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 1024px) {
  .topNews-list li {
    width: calc(50% - (7rem / 2));
  }
  .topNews-list li:nth-of-type(odd) {
    margin-right: 7rem;
  }
  .topNews-list li:nth-of-type(n+3) {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 641px) {
  .topNews-list {
    flex-direction: row;
  }
}

@media screen and (max-width: 641px) {
  .topNews-list li {
    width: calc(50% - (1rem / 2));
  }
  .topNews-list li:nth-of-type(odd) {
    margin-right: 1rem;
  }
  .topNews-list li:nth-of-type(n+3) {
    margin-top: 1rem;
  }
}

.topNews-list a {
  display: block;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 642px) {
  .topNews-list a {
    transition: ease 300ms;
  }
  .topNews-list a:hover {
    opacity: .65;
  }
}

.topNews-list_img {
  position: relative;
  width: 100%;
  overflow: hidden;
  background: #000;
  margin-bottom: 2.5rem;
}

.topNews-list_img img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.topNews-list_img img {
  width: auto;
  height: 101%;
  max-width: initial;
  max-width: none;
}

@supports (object-fit: cover) {
  .topNews-list_img img {
    width: 101%;
    height: 101%;
    object-fit: cover;
  }
}

.topNews-list_img::after {
  content: "";
  display: block;
  padding-top: 60%;
}

.topNews-list-cont_cat {
  font-size: 1.3rem;
  color: #FFF;
  font-weight: 600;
  display: inline-block;
  background: black;
  padding: .5rem 1rem;
  margin-bottom: 1rem;
}

.topNews-list-cont_day {
  width: 100%;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.topNews-list-cont_ttl {
  line-height: 2;
}

.topNews li {
  display: flex;
}

/* concept
-----------------------------*/
@media screen and (min-width: 642px) {
  .topConcept {
    margin-bottom: 14rem;
  }
}

@media screen and (max-width: 1024px) {
  .topConcept {
    margin-bottom: calc(14rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .topConcept {
    margin-bottom: calc(14rem * .5);
  }
}

.topConcept-inner {
  position: relative;
}

@media screen and (min-width: 1025px) {
  .topConcept-inner {
    display: flex;
    align-items: flex-start;
  }
}

@media screen and (max-width: 1024px) {
  .topConcept-inner {
    display: block;
  }
}

.topConcept-cont {
  width: calc(42% + 6rem);
  background: #eaeaec;
  box-shadow: 15px 15px 35px rgba(0, 0, 0, 0.25);
}

@media screen and (min-width: 642px) {
  .topConcept-cont {
    padding-top: 6rem;
  }
}

@media screen and (max-width: 1024px) {
  .topConcept-cont {
    padding-top: calc(6rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .topConcept-cont {
    padding-top: calc(6rem * .5);
  }
}

@media screen and (min-width: 642px) {
  .topConcept-cont {
    padding-bottom: 10rem;
  }
}

@media screen and (max-width: 1024px) {
  .topConcept-cont {
    padding-bottom: calc(10rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .topConcept-cont {
    padding-bottom: calc(10rem * .5);
  }
}

@media screen and (min-width: 1025px) {
  .topConcept-cont {
    margin-right: -6rem;
    order: -1;
  }
}

@media screen and (max-width: 1024px) {
  .topConcept-cont {
    width: calc(100% - 2rem);
    padding-right: 3rem;
    padding-top: 6rem;
    margin-top: -4rem;
  }
}

@media screen and (min-width: 1025px) {
  .topConcept-cont_ttl {
    max-width: 36rem;
    margin-bottom: 4rem;
  }
}

.topConcept-cont-inner {
  margin: 0 auto;
}

@media screen and (min-width: 1025px) {
  .topConcept-cont-inner {
    max-width: calc(30rem + 18rem);
    padding-left: 18rem;
  }
}

@media screen and (max-width: 1024px) {
  .topConcept-cont-inner {
    padding-left: calc(20vw + 2rem);
  }
}

.topConcept-cont_copy {
  writing-mode: vertical-rl;
  font-size: 3rem;
  line-height: 1.8;
  letter-spacing: 0.12em;
  font-weight: 500;
  max-height: 25rem;
}

@media screen and (min-width: 642px) {
  .topConcept-cont_copy {
    margin-bottom: 8rem;
  }
}

@media screen and (max-width: 1024px) {
  .topConcept-cont_copy {
    margin-bottom: calc(8rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .topConcept-cont_copy {
    margin-bottom: calc(8rem * .5);
  }
}

.topConcept-cont_txt {
  margin-bottom: 3rem;
  line-height: 2;
}

@media screen and (min-width: 1025px) {
  .topConcept_img {
    max-width: 58%;
    flex: 0 0 auto;
  }
}

@media screen and (max-width: 1024px) {
  .topConcept_img {
    width: calc(100% - 2rem);
    margin: 0 0 0 auto;
  }
}

.topConcept_img img {
  width: 100%;
}

.topConcept-parts01 {
  position: absolute;
  left: 0;
  bottom: -3rem;
}

@media screen and (max-width: 1024px) {
  .topConcept-parts01 {
    bottom: -6vw;
    width: 20vw;
  }
}

/* alternative
-----------------------------*/
@media screen and (min-width: 642px) {
  .topAlt {
    margin-bottom: 14rem;
  }
}

@media screen and (max-width: 1024px) {
  .topAlt {
    margin-bottom: calc(14rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .topAlt {
    margin-bottom: calc(14rem * .5);
  }
}

.topAlt-inner {
  overflow: hidden;
}

@media screen and (min-width: 1025px) {
  .topAlt_ttl {
    margin-bottom: 8rem;
  }
}

@media screen and (min-width: 1025px) {
  .topAlt-cont {
    max-width: calc(1335px + 6rem);
    padding: 0 3rem;
    margin-left: auto;
    margin-right: auto;
    margin-right: -220px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1024px) {
  .topAlt-cont {
    max-width: calc(1335px + 4rem);
    padding: 0 2rem;
  }
}

.topAlt-cont_ttl {
  font-size: 2.5rem;
  letter-spacing: .22em;
  margin-bottom: 3.5rem;
  font-weight: 600;
}

@media screen and (max-width: 1024px) {
  .topAlt-cont_ttl {
    padding: 0 2rem;
  }
}

.topAlt-cont-list {
  margin-bottom: 4.5rem;
}

.topAlt-cont-list li {
  margin-right: 4.5rem;
}

@media screen and (max-width: 1024px) {
  .topAlt-cont-list li {
    margin: 0 2rem;
  }
}

.topAlt-cont-list_img {
  position: relative;
  width: 100%;
  overflow: hidden;
  background: #000;
  margin-bottom: 2rem;
}

.topAlt-cont-list_img img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.topAlt-cont-list_img img {
  width: 101%;
  height: auto;
  max-width: 101%;
}

@supports (object-fit: cover) {
  .topAlt-cont-list_img img {
    width: 101%;
    height: 101%;
    object-fit: cover;
  }
}

.topAlt-cont-list_img::after {
  content: "";
  display: block;
  padding-top: 100%;
}

.topAlt-cont-list_ttl {
  line-height: 1.5;
  letter-spacing: 0.12em;
}

@media screen and (max-width: 1024px) {
  .topAlt-cont_more {
    padding: 0 2rem;
  }
}

/* story
-----------------------------*/
@media screen and (min-width: 642px) {
  .topStory {
    margin-bottom: 14rem;
  }
}

@media screen and (max-width: 1024px) {
  .topStory {
    margin-bottom: calc(14rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .topStory {
    margin-bottom: calc(14rem * .5);
  }
}

.topStory-inner {
  position: relative;
}

@media screen and (min-width: 1025px) {
  .topStory-inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.topStory-cont {
  width: 35%;
}

@media screen and (min-width: 642px) {
  .topStory-cont {
    padding-top: 6rem;
  }
}

@media screen and (max-width: 1024px) {
  .topStory-cont {
    padding-top: calc(6rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .topStory-cont {
    padding-top: calc(6rem * .5);
  }
}

@media screen and (min-width: 642px) {
  .topStory-cont {
    padding-bottom: 10rem;
  }
}

@media screen and (max-width: 1024px) {
  .topStory-cont {
    padding-bottom: calc(10rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .topStory-cont {
    padding-bottom: calc(10rem * .5);
  }
}

@media screen and (max-width: 1024px) {
  .topStory-cont {
    width: calc(100% - 2rem);
    margin: 0 0 0 auto;
  }
}

.topStory-cont-inner {
  margin: 0 auto 0 0;
  padding-left: 4.5rem;
}

@media screen and (min-width: 1025px) {
  .topStory-cont-inner {
    max-width: calc(30rem + 4.5rem);
  }
}

.topStory-cont_ttl {
  margin-left: auto;
  text-align: left;
}

@media screen and (min-width: 1025px) {
  .topStory-cont_ttl {
    width: 100%;
    margin-bottom: 7rem;
    max-width: 45rem;
  }
}

.topStory-cont_copy {
  writing-mode: vertical-rl;
  font-size: 3rem;
  line-height: 1.8;
  letter-spacing: 0.12em;
  font-weight: 500;
  max-height: 25rem;
}

@media screen and (min-width: 642px) {
  .topStory-cont_copy {
    margin-bottom: 8rem;
  }
}

@media screen and (max-width: 1024px) {
  .topStory-cont_copy {
    margin-bottom: calc(8rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .topStory-cont_copy {
    margin-bottom: calc(8rem * .5);
  }
}

.topStory-cont_txt {
  margin-bottom: 3rem;
  line-height: 2;
}

.topStory_img {
  flex: 0 0 auto;
  width: 65%;
}

@media screen and (max-width: 1024px) {
  .topStory_img {
    width: calc(100% - 2rem);
  }
}

.topStory_img img {
  width: 100%;
}

.topStory-parts01 {
  position: absolute;
  right: 0;
}

@media screen and (min-width: 1025px) {
  .topStory-parts01 {
    top: 23rem;
  }
}

@media screen and (max-width: 1024px) {
  .topStory-parts01 {
    bottom: -10vw;
    width: 40vw;
    z-index: -1;
  }
}

/* company
-----------------------------*/
@media screen and (min-width: 642px) {
  .topCompany {
    margin-bottom: 20rem;
  }
}

@media screen and (max-width: 1024px) {
  .topCompany {
    margin-bottom: calc(20rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .topCompany {
    margin-bottom: calc(20rem * .5);
  }
}

.topCompany-inner {
  position: relative;
}

@media screen and (min-width: 1025px) {
  .topCompany-inner {
    display: flex;
    align-items: flex-start;
  }
}

.topCompany-cont {
  width: calc(44% + 3rem);
  background: #eaeaec;
  box-shadow: 15px 15px 35px rgba(0, 0, 0, 0.25);
}

@media screen and (min-width: 642px) {
  .topCompany-cont {
    padding-top: 8rem;
  }
}

@media screen and (max-width: 1024px) {
  .topCompany-cont {
    padding-top: calc(8rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .topCompany-cont {
    padding-top: calc(8rem * .5);
  }
}

@media screen and (min-width: 642px) {
  .topCompany-cont {
    padding-bottom: 9rem;
  }
}

@media screen and (max-width: 1024px) {
  .topCompany-cont {
    padding-bottom: calc(9rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .topCompany-cont {
    padding-bottom: calc(9rem * .5);
  }
}

@media screen and (min-width: 1025px) {
  .topCompany-cont {
    margin-top: 5rem;
    margin-right: -3rem;
    order: -1;
  }
}

@media screen and (max-width: 1024px) {
  .topCompany-cont {
    margin-top: -2rem;
    width: calc(100% - 2rem);
    position: relative;
    z-index: 1;
  }
}

.topCompany-cont_ttl {
  max-width: 36rem;
  margin-bottom: 4rem;
}

.topCompany-cont-inner {
  margin: 0 auto;
}

@media screen and (min-width: 1025px) {
  .topCompany-cont-inner {
    max-width: calc(30rem + 18rem);
    padding-left: 18rem;
  }
}

@media screen and (max-width: 1024px) {
  .topCompany-cont-inner {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
}

.topCompany-cont_copy {
  writing-mode: vertical-rl;
  font-size: 3rem;
  line-height: 1.8;
  letter-spacing: 0.12em;
  font-weight: 500;
  max-height: 25rem;
  writing-mode: inherit;
  margin-bottom: 1rem;
}

.topCompany-cont_txt {
  margin-bottom: 4rem;
  line-height: 2;
}

.topCompany_img {
  flex: 0 0 auto;
  width: 56%;
}

@media screen and (max-width: 1024px) {
  .topCompany_img {
    width: 100%;
  }
}

.topCompany_img img {
  width: 100%;
}

.topCompany-parts01 {
  position: absolute;
  left: 0;
  bottom: -3rem;
}

/* brands
-----------------------------*/
@media screen and (min-width: 642px) {
  .topBrands {
    margin-bottom: 17rem;
  }
}

@media screen and (max-width: 1024px) {
  .topBrands {
    margin-bottom: calc(17rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .topBrands {
    margin-bottom: calc(17rem * .5);
  }
}

@media screen and (min-width: 1025px) {
  .topBrands_ttl {
    margin-bottom: 6rem;
  }
}

@media screen and (min-width: 1025px) {
  .topBrands .brandsBox {
    display: flex;
  }
}

.topBrands .brandsBox:not(:last-of-type) {
  margin-bottom: 9rem;
}

.topBrands .brandsBox-cont {
  width: 29rem;
  flex: 0 0 auto;
  margin: 0 4rem;
}

@media screen and (max-width: 1024px) {
  .topBrands .brandsBox-cont {
    width: 60%;
  }
}

@media screen and (max-width: 1024px) {
  .topBrands .brandsBox_img {
    width: calc(100% - 2rem);
    margin-bottom: 3rem;
  }
}

@media screen and (min-width: 1025px) {
  .topBrands .brandsBox-rl {
    justify-content: flex-end;
  }
}

@media screen and (min-width: 1025px) {
  .topBrands .brandsBox-rl .brandsBox-cont {
    order: -1;
  }
}

@media screen and (max-width: 1024px) {
  .topBrands .brandsBox-rl .brandsBox_img {
    margin-left: auto;
  }
}

.topBrands .brandsBox-cont_ttl {
  font-size: 3rem;
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: 3rem;
}

.topBrands .brandsBox-cont_txt {
  line-height: 2;
  margin-bottom: 4rem;
}

/* contact
-----------------------------*/
.topContact {
  padding: 5rem 0 0;
  background: #eaeaec;
}

@media screen and (min-width: 642px) {
  .topContact {
    padding-bottom: 13rem;
  }
}

@media screen and (max-width: 1024px) {
  .topContact {
    padding-bottom: calc(13rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .topContact {
    padding-bottom: calc(13rem * .5);
  }
}

@media screen and (min-width: 1301px) {
  .topContact {
    width: 100vw;
    margin-left: calc( ( 100% - 100vw ) * .5);
    margin-right: calc( ( 100% - 100vw ) * .5);
  }
}

.topContact_ttl {
  margin-bottom: 7rem;
}

.topContact-list {
  max-width: calc(880px + 6rem);
  padding: 0 3rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 1024px) {
  .topContact-list {
    max-width: calc(880px + 4rem);
    padding: 0 2rem;
  }
}

@media screen and (max-width: 641px) {
  .topContact-list {
    flex-direction: column;
  }
}

@media screen and (min-width: 642px) {
  .topContact-list li {
    width: calc(50% - (6rem / 2));
  }
  .topContact-list li:nth-of-type(odd) {
    margin-right: 6rem;
  }
  .topContact-list li:nth-of-type(n+3) {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 641px) {
  .topContact-list {
    flex-direction: column;
  }
}

@media screen and (max-width: 641px) {
  .topContact-list li {
    width: 100%;
  }
  .topContact-list li:nth-of-type(odd) {
    margin-right: 0;
  }
  .topContact-list li:not(:first-of-type) {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  .topContact-list {
    width: 80%;
  }
}

.topContact-list a {
  display: block;
  border-top: 5px solid #000;
  border-bottom: 5px solid #000;
  transition: ease 0.3s;
}

.topContact-list a:hover {
  opacity: 0.6;
}

.topContact-list img {
  width: 100%;
  vertical-align: bottom;
}

/*******************************

    foot

*******************************/
.foot-top {
  background: #000;
}

.foot-top-inner {
  max-width: calc(1240px + 6rem);
  padding: 0 3rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

@media screen and (max-width: 1024px) {
  .foot-top-inner {
    max-width: calc(1240px + 4rem);
    padding: 0 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .foot-top-inner {
    flex-direction: column;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

@media screen and (min-width: 1025px) {
  .foot-top-inner > div {
    width: 50%;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@media screen and (min-width: 1025px) {
  .foot-l {
    border-right: 1px solid #FFF;
  }
}

@media screen and (max-width: 1024px) {
  .foot-l {
    margin-bottom: 4rem;
  }
}

@media screen and (min-width: 1025px) {
  .foot-r {
    padding-left: 6%;
  }
}

.foot-add {
  color: #FFF;
}

.foot-add a {
  color: #FFF;
  transition: ease 0.3s;
}

.foot-add a:hover {
  opacity: 0.6;
}

.foot-add:not(:last-of-type) {
  margin-bottom: 6rem;
}

@media screen and (max-width: 1024px) {
  .foot-add:not(:last-of-type) {
    margin-bottom: 3rem;
  }
}

.foot-add_ttl {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.foot-add_ttl span {
  line-height: 1.5;
  margin-top: 1.5rem;
  display: block;
  font-size: 1.3rem;
}

@media screen and (max-width: 1024px) {
  .foot-add_ttl span {
    font-size: 1.4rem;
  }
}

.foot-add_add {
  line-height: 1.5;
  margin-bottom: 1rem;
  display: flex;
}

.foot-add_add span:first-of-type {
  flex: 0 0 auto;
  margin-right: 0.5em;
}

.foot-add_tel {
  line-height: 1.5;
}

.foot-nav {
  display: flex;
}

.foot-nav ul {
  width: 45%;
}

.foot-nav li {
  font-size: 1.6rem;
}

@media screen and (min-width: 1025px) {
  .foot-nav li:not(:last-of-type) {
    margin-bottom: 3rem;
  }
}

.foot-nav li a {
  transition: ease 0.3s;
  font-weight: 600;
  color: #FFF;
}

.foot-nav li a:hover {
  opacity: 0.6;
}

@media screen and (max-width: 1024px) {
  .foot-nav li a {
    display: block;
    padding: 1rem 0;
  }
}

.foot-bottom {
  position: relative;
  padding-bottom: 5rem;
}

@media screen and (max-width: 1024px) {
  .foot-bottom {
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
    padding-top: 1.5rem;
  }
}

.foot-credit {
  margin: 0 auto;
  padding: 0 20rem;
}

@media screen and (max-width: 1024px) {
  .foot-credit {
    padding: 0 3rem;
  }
}

.foot-logo {
  padding: 2.5rem 0 1.5rem;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .foot-logo {
    width: 18rem;
    margin: 0 auto;
    padding: 3rem 0 2rem;
  }
}

.foot-logo a {
  transition: ease 0.3s;
  display: inline-block;
}

.foot-logo a:hover {
  opacity: 0.6;
}

.foot-copy {
  text-align: center;
  font-size: 1.1rem;
  letter-spacing: 0.2em;
  font-weight: 400;
}

@media screen and (min-width: 1025px) {
  .foot-sns {
    position: absolute;
    top: 6rem;
    right: 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .foot-sns {
    order: -1;
  }
}

.foot-sns-list {
  display: flex;
}

@media screen and (max-width: 1024px) {
  .foot-sns-list {
    justify-content: flex-end;
  }
}

.foot-sns-list li {
  margin-right: 3rem;
}

@media screen and (max-width: 1024px) {
  .foot-sns-list li {
    margin: 0 1rem;
    display: flex;
    align-items: center;
  }
}

.foot-sns-list a {
  display: flex;
  align-items: center;
  width: 1.6rem;
  height: 100%;
  transition: ease 0.3s;
}

.foot-sns-list a:hover {
  opacity: 0.6;
}

@media screen and (max-width: 1024px) {
  .foot-sns-list a {
    width: 2rem;
  }
}

.foot-bnr {
  padding: 0 2rem;
}

@media screen and (min-width: 642px) {
  .foot-bnr {
    margin-top: 7.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .foot-bnr {
    margin-top: calc(7.5rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .foot-bnr {
    margin-top: calc(7.5rem * .5);
  }
}

@media screen and (min-width: 642px) {
  .foot-bnr {
    margin-bottom: 12rem;
  }
}

@media screen and (max-width: 1024px) {
  .foot-bnr {
    margin-bottom: calc(12rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .foot-bnr {
    margin-bottom: calc(12rem * .5);
  }
}

.foot-bnr p {
  text-align: center;
}

.foot-bnr p:not(:last-of-type) {
  margin-bottom: 6rem;
}

@media screen and (max-width: 1024px) {
  .foot-bnr p:not(:last-of-type) {
    margin-bottom: 3rem;
  }
}

.foot-bnr a {
  display: inline-block;
  box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.37);
  transition: ease 0.3s;
}

.foot-bnr a:hover {
  opacity: 0.6;
}

@media screen and (max-width: 1024px) {
  .foot-bnr a {
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.37);
  }
}

.foot-fixed-bnr {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background: #000;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
}

.foot-fixed-bnr.is-active {
  visibility: visible;
  opacity: 1;
}

.foot-fixed-bnr p {
  text-align: center;
}

@media screen and (max-width: 641px) {
  .foot-fixed-bnr img {
    width: 100%;
  }
}

/*******************************

    lowerKv

*******************************/
.lowerKv {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 350px;
}

@media screen and (min-width: 1025px) {
  .lowerKv {
    height: calc(70vh - 8rem);
  }
}

@media screen and (min-width: 1025px) and (min-width: 642px) {
  .lowerKv {
    margin-bottom: 14rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1024px) {
  .lowerKv {
    margin-bottom: calc(14rem * .85);
  }
}

@media screen and (min-width: 1025px) and (max-width: 641px) {
  .lowerKv {
    margin-bottom: calc(14rem * .5);
  }
}

@media screen and (max-width: 1024px) {
  .lowerKv {
    min-height: 200px;
    margin-bottom: 6rem;
    height: 200px;
    width: calc(100% - 2rem);
    margin-left: auto;
    margin-right: auto;
  }
}

.lowerKv_ttl {
  font-size: 6rem;
  letter-spacing: 0.12em;
  color: #FFF;
  padding-top: 2rem;
  font-weight: 400;
}

@media screen and (max-width: 1024px) {
  .lowerKv_ttl {
    font-size: 7vw;
  }
}

.conceptKv {
  background: url(../img/lower-kv01.png) center center no-repeat;
  background-size: contain;
}

.conceptKv_copy {
  position: absolute;
  bottom: 0rem;
  right: 27rem;
  z-index: 1;
}

.brandsKv {
  background: url(../img/lower-kv01.png) center center no-repeat;
  background-size: contain;
}

@media screen and (min-width: 1025px) {
  .brandsKv {
    margin-bottom: 10rem;
  }
}

.restKv {
  background: url(../img/lower-kv02.png) center center no-repeat;
  background-size: contain;
}

@media screen and (min-width: 1025px) {
  .restKv {
    margin-bottom: 10rem;
  }
}

.companyKv {
  background: url(../img/lower-kv03.png) center center no-repeat;
  background-size: contain;
}

@media screen and (min-width: 1025px) {
  .companyKv {
    margin-bottom: 10rem;
  }
}

.storyKv {
  background: url(../img/lower-kv02.png) center center no-repeat;
  background-size: contain;
}

@media screen and (min-width: 1025px) {
  .storyKv {
    margin-bottom: 13.5rem;
  }
}

.alterKv {
  background: url(../img/lower-kv03.png) center center no-repeat;
  background-size: contain;
}

@media screen and (min-width: 1025px) {
  .alterKv {
    margin-bottom: 10rem;
  }
}

.contactKv {
  background: url(../img/contact-kv.jpg) center center no-repeat;
  background-size: cover;
  justify-content: flex-start;
}

@media screen and (min-width: 1025px) {
  .contactKv {
    margin-bottom: 10rem;
  }
}

@media screen and (max-width: 1024px) {
  .contactKv {
    height: 30vh;
    min-height: 250px;
    width: 100%;
  }
}

.contactKv::after, .newsKv::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 4.6rem;
  background: #FFF;
  z-index: 1;
}

@media screen and (max-width: 1024px) {
  .contactKv::after, .newsKv::after {
    display: none;
  }
}

.contactKv_txt {
  position: absolute;
  left: 0;
  bottom: 6rem;
  padding: 3.5rem 13% 3.5rem 19%;
  line-height: 2;
  font-size: 1.6rem;
  background: rgba(0, 0, 0, 0.8);
  color: #FFF;
}

@media screen and (max-width: 1024px) {
  .contactKv_txt {
    padding: 1.5rem 5% 1.5rem 10%;
  }
}

.contactKv_ttl, .newsKv_ttl {
  padding-left: 19%;
  padding-top: 0;
  padding-bottom: 15rem;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .contactKv_ttl, .newsKv_ttl {
    font-size: 4rem;
    padding-bottom: 13rem;
    padding-left: 10%;
  }
}

.contactKv_ttl span, .newsKv_ttl span {
  display: block;
  font-size: 3rem;
  margin-top: 5rem;
}

@media screen and (max-width: 1024px) {
  .contactKv_ttl span, .newsKv_ttl span {
    margin-top: 2rem;
    font-size: 2rem;
  }
}

.newsKv {
  background: url(../img/news-kv.jpg) center center no-repeat;
  background-size: cover;
  justify-content: flex-start;
}

@media screen and (min-width: 1025px) {
  .newsKv {
    margin-bottom: 9rem;
  }
}

@media screen and (max-width: 1024px) {
  .newsKv {
    height: 30vh;
    min-height: 250px;
    width: 100%;
  }
}

.newsKv_ttl span {
  display: block;
  font-size: 3rem;
  margin-top: 5rem;
}

@media screen and (max-width: 1024px) {
  .newsKv_ttl span {
    margin-top: 2rem;
    font-size: 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .newsKv_ttl {
    padding-bottom: 0;
  }
}

/*******************************

    concept

*******************************/
.conceptKv {
  margin-bottom: calc(20vw - 6rem);
}

@media screen and (max-width: 1024px) {
  .conceptKv_copy {
    bottom: -20vw;
    right: 6.5vw;
    width: 20vw;
  }
}

@media screen and (min-width: 1025px) {
  .conceptMain-inner {
    background: url(../img/concept-bg-l.png) left top 12rem no-repeat, url(../img/concept-bg-r.png) top 3.5rem right no-repeat;
    background-size: 382px auto, 228px auto;
  }
}

@media screen and (min-width: 1025px) and (min-width: 642px) {
  .conceptMain-inner {
    padding-bottom: 19rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1024px) {
  .conceptMain-inner {
    padding-bottom: calc(19rem * .85);
  }
}

@media screen and (min-width: 1025px) and (max-width: 641px) {
  .conceptMain-inner {
    padding-bottom: calc(19rem * .5);
  }
}

.conceptBox {
  display: flex;
}

@media screen and (min-width: 1025px) {
  .conceptBox {
    max-width: calc(50% + 6rem);
    padding: 0 3rem;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1024px) {
  .conceptBox {
    max-width: calc(50% + 4rem);
    padding: 0 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .conceptBox {
    padding: 0 4rem;
    flex-direction: column;
  }
}

.conceptBox-inner {
  display: flex;
  align-items: flex-start;
  position: relative;
  justify-content: space-between;
}

.conceptBox-cont {
  flex: 0 0 auto;
  z-index: 1;
}

.conceptBox-cont_ttl {
  font-size: 5rem;
  max-width: 45rem;
  margin: 0 0 5rem auto;
  text-align: left;
  font-weight: 500;
}

@media screen and (min-width: 1025px) {
  .conceptBox-cont_ttl {
    width: 100%;
  }
}

.conceptBox-cont_copy {
  writing-mode: vertical-rl;
  font-size: 3rem;
  line-height: 1.8;
  letter-spacing: 0.12em;
  font-weight: 500;
  max-height: 25rem;
  font-size: 2rem;
  margin-bottom: 2.5rem;
  writing-mode: inherit;
  margin-left: 0;
}

.conceptBox-cont_txt {
  margin-bottom: 3rem;
  line-height: 2;
}

.conceptBox_img {
  width: 100%;
  z-index: -1;
}

@media screen and (max-width: 1024px) {
  .conceptBox_img {
    width: 80%;
    margin: 0 auto 3rem;
  }
}

.conceptBox_img img {
  width: 100%;
}

@media screen and (min-width: 1025px) {
  .conceptBox01 {
    margin-bottom: 15rem;
  }
}

@media screen and (max-width: 1024px) {
  .conceptBox01 {
    padding-top: 10rem;
    padding-bottom: 15rem;
    background: url(../img/concept-parts01.png) left top no-repeat, url(../img/concept-parts02.png) right bottom no-repeat;
    background-size: 6rem auto, 12rem auto;
  }
}

@media screen and (min-width: 1025px) {
  .conceptBox01 .conceptBox-cont {
    order: -1;
    width: 45%;
    padding-top: 3rem;
    padding-left: 5%;
  }
}

@media screen and (min-width: 1025px) {
  .conceptBox01 .conceptBox_img {
    margin-left: -10%;
  }
}

@media screen and (min-width: 1025px) {
  .conceptBox02 {
    margin-bottom: 12rem;
  }
}

@media screen and (max-width: 1024px) {
  .conceptBox02 {
    padding-bottom: 15rem;
    background: url(../img/concept-parts03.png) left bottom 1rem no-repeat;
    background-size: 10rem auto;
  }
}

.conceptBox02 .conceptBox-cont {
  position: relative;
  padding-right: 5rem;
}

@media screen and (min-width: 1025px) {
  .conceptBox02 .conceptBox-cont {
    margin-top: 8rem;
  }
}

.conceptBox02 .conceptBox-cont_ttl {
  writing-mode: vertical-rl;
  position: absolute;
  top: 0;
  right: -1rem;
}

@media screen and (max-width: 1024px) {
  .conceptBox02 .conceptBox-cont_ttl {
    right: 1rem;
  }
}

.conceptBox02 .conceptBox-cont_copy {
  padding-top: 8rem;
}

@media screen and (max-width: 1024px) {
  .conceptBox02 .conceptBox-cont_copy {
    padding-top: 3rem;
  }
}

@media screen and (min-width: 1025px) {
  .conceptBox02 .conceptBox_img {
    margin-right: 3%;
    margin-left: -5%;
  }
}

@media screen and (min-width: 1025px) {
  .conceptBox03 {
    margin-bottom: 12rem;
  }
}

@media screen and (max-width: 1024px) {
  .conceptBox03 {
    padding-bottom: 12rem;
    background: url(../img/concept-parts04.png) right bottom 1rem no-repeat;
    background-size: 15rem auto;
  }
}

@media screen and (min-width: 1025px) {
  .conceptBox03 .conceptBox-cont {
    order: -1;
    width: 45%;
    padding-top: 3rem;
    padding-left: 3%;
  }
}

@media screen and (min-width: 1025px) {
  .conceptBox03 .conceptBox_img {
    padding-top: 3rem;
    margin-left: -15%;
    margin-right: -5%;
  }
}

.conceptBox04 .conceptBox {
  position: relative;
}

@media screen and (min-width: 1025px) {
  .conceptBox04 .conceptBox-cont {
    padding-top: 1rem;
    padding-left: 3%;
    margin-right: -3%;
  }
}

@media screen and (min-width: 1025px) {
  .conceptBox04 .conceptBox_img {
    margin-left: -7%;
  }
}

@media screen and (max-width: 1024px) {
  .conceptBox04 .conceptBox_img {
    margin-left: -1%;
  }
}

/*******************************

    brands

*******************************/
.brands .brandsBox-head img {
  width: 92%;
}

@media screen and (max-width: 1024px) {
  .brands .brandsBox-head img {
    width: 100%;
  }
}

.brands .brandsBox-main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media screen and (max-width: 1024px) {
  .brands .brandsBox-main {
    flex-direction: column;
  }
}

.brands .brandsBox-main figure {
  margin-top: -7rem;
  width: 46%;
  box-shadow: -15px 20px 30px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 1024px) {
  .brands .brandsBox-main figure {
    width: 50%;
    order: -1;
    z-index: 1;
    box-shadow: -7px 10px 15px rgba(0, 0, 0, 0.3);
  }
}

.brands .brandsBox-main figure img {
  vertical-align: bottom;
}

.brands .brandsBox-cont {
  width: 50%;
  padding: 5rem 18% 17rem 8%;
  background: #f6f3ee;
}

@media screen and (max-width: 1024px) {
  .brands .brandsBox-cont {
    width: 100%;
    position: relative;
    margin-top: -3rem;
    padding: 5rem 10% 13rem 10%;
  }
}

.brands .brandsBox-cont_ttl {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 3.5rem;
}

@media screen and (max-width: 1024px) {
  .brands .brandsBox-cont_ttl {
    margin-top: 4rem;
  }
}

.brands .brandsBox-cont_txt {
  line-height: 2;
  margin-bottom: 3rem;
}

.brands .brandsBox:nth-of-type(even) .brandsBox-head {
  text-align: right;
}

.brands .brandsBox:nth-of-type(even) .brandsBox-main figure {
  order: -1;
}

.brands .brandsBox:nth-of-type(odd) .brandsBox-main figure {
  box-shadow: 15px 20px 30px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 1024px) {
  .brands .brandsBox:nth-of-type(odd) .brandsBox-main figure {
    box-shadow: 7px 10px 15px rgba(0, 0, 0, 0.3);
  }
}

@media screen and (min-width: 1025px) {
  .brands .brandsBox:nth-of-type(odd) .brandsBox-cont {
    padding-right: 8%;
    padding-left: 18%;
  }
}

/*******************************

    restaurant

*******************************/
.rest .restBox-head img {
  width: 92%;
}

@media screen and (max-width: 1024px) {
  .rest .restBox-head img {
    width: 100%;
  }
}

.rest .restBox-main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media screen and (max-width: 1024px) {
  .rest .restBox-main {
    flex-direction: column;
  }
}

.rest .restBox-main figure {
  margin-top: -7rem;
  width: 46%;
  box-shadow: -15px 20px 30px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 1024px) {
  .rest .restBox-main figure {
    width: 50%;
    order: -1;
    z-index: 1;
    box-shadow: -7px 10px 15px rgba(0, 0, 0, 0.3);
  }
}

.rest .restBox-main figure img {
  vertical-align: bottom;
}

.rest .restBox-cont {
  width: 50%;
  padding: 5rem 18% 17rem 8%;
  background: #f6f3ee;
}

@media screen and (max-width: 1024px) {
  .rest .restBox-cont {
    width: 100%;
    position: relative;
    margin-top: -3rem;
    padding: 5rem 10% 13rem 10%;
  }
}

.rest .restBox-cont_ttl {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 3.5rem;
}

@media screen and (max-width: 1024px) {
  .rest .restBox-cont_ttl {
    margin-top: 4rem;
  }
}

.rest .restBox-cont_txt {
  line-height: 2;
  margin-bottom: 3rem;
}

.rest .restBox:nth-of-type(even) .restBox-head {
  text-align: right;
}

.rest .restBox:nth-of-type(even) .restBox-main figure {
  order: -1;
}

.rest .restBox:nth-of-type(odd) .restBox-main figure {
  box-shadow: 15px 20px 30px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 1024px) {
  .rest .restBox:nth-of-type(odd) .restBox-main figure {
    box-shadow: 7px 10px 15px rgba(0, 0, 0, 0.3);
  }
}

@media screen and (min-width: 1025px) {
  .rest .restBox:nth-of-type(odd) .restBox-cont {
    padding-right: 8%;
    padding-left: 18%;
  }
}

@media screen and (min-width: 642px) {
  .rest .restBox {
    margin-bottom: 27rem;
  }
}

@media screen and (max-width: 1024px) {
  .rest .restBox {
    margin-bottom: calc(27rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .rest .restBox {
    margin-bottom: calc(27rem * .5);
  }
}

.rest .restBox_ttl {
  text-align: center;
  font-size: 3rem;
  margin-bottom: 5rem;
  font-weight: 600;
  letter-spacing: 0.12em;
  padding-left: 6rem;
}

@media screen and (max-width: 1024px) {
  .rest .restBox_ttl {
    padding-left: 0;
  }
}

.rest .restBox-main {
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 642px) {
  .rest .restBox-main {
    margin-bottom: 9rem;
  }
}

@media screen and (max-width: 1024px) {
  .rest .restBox-main {
    margin-bottom: calc(9rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .rest .restBox-main {
    margin-bottom: calc(9rem * .5);
  }
}

.rest .restBox-head {
  position: relative;
  text-align: right;
}

.rest .restBox-head .txt {
  position: absolute;
  font-size: 1.8rem;
  letter-spacing: .12em;
  top: 4rem;
  right: 4rem;
  text-align: left;
  line-height: 1.2;
}

@media screen and (max-width: 1024px) {
  .rest .restBox-head .txt {
    top: 1.5rem;
    right: calc(1.5rem + 5px);
    font-size: 3vw;
  }
}

.rest .restBox-head .thumb {
  position: absolute;
  bottom: 3rem;
  right: 4rem;
  width: 16.2rem;
}

@media screen and (max-width: 1024px) {
  .rest .restBox-head .thumb {
    width: 20vw;
    max-width: 16.2rem;
    bottom: 1rem;
    right: calc(1rem + 5px);
  }
}

.rest .restBox-head .line {
  width: 15px;
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  background: #000;
}

@media screen and (max-width: 1024px) {
  .rest .restBox-head .line {
    width: 5px;
  }
}

@media screen and (min-width: 1025px) {
  .rest .restBox-cont {
    padding-top: 10rem;
    padding-bottom: 5rem;
  }
}

@media screen and (max-width: 1024px) {
  .rest .restBox-cont {
    width: 100%;
    position: relative;
    margin-top: -3rem;
    padding: 5rem 10%;
  }
}

.rest .restBox-cont_ttl span {
  display: block;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.rest .restBox-cont_txt {
  margin-bottom: 3.5rem;
  font-size: 1.4rem;
}

.rest .restBox-cont_add {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  line-height: 1.75;
}

.rest .restBox:nth-of-type(odd) .restBox-head .txt {
  color: #7e2618;
}

.rest .restBox:nth-of-type(odd) .restBox-head .line {
  background: #7e2618;
}

@media screen and (min-width: 1025px) {
  .rest .restBox:nth-of-type(odd) .restBox-head .thumb {
    bottom: 10rem;
  }
}

.rest .restBox:nth-of-type(odd) .restBox-head_img {
  background: #f6f3ee;
}

.rest .restBox:nth-of-type(even) .restBox-head .txt {
  color: #435318;
}

.rest .restBox:nth-of-type(even) .restBox-head .line {
  background: #435318;
}

.rest .restBox:nth-of-type(even) .restBox-main figure {
  box-shadow: 15px 20px 30px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 1024px) {
  .rest .restBox:nth-of-type(even) .restBox-main figure {
    box-shadow: 7px 10px 15px rgba(0, 0, 0, 0.3);
  }
}

@media screen and (min-width: 642px) {
  .rest .restBox02 .restBox-main {
    margin-bottom: 14rem;
  }
}

@media screen and (max-width: 1024px) {
  .rest .restBox02 .restBox-main {
    margin-bottom: calc(14rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .rest .restBox02 .restBox-main {
    margin-bottom: calc(14rem * .5);
  }
}

.rest .restInfo {
  max-width: calc(880px + 6rem);
  padding: 0 3rem;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1024px) {
  .rest .restInfo {
    max-width: calc(880px + 4rem);
    padding: 0 2rem;
  }
}

.rest .restInfo_ttl {
  font-size: 2rem;
  margin-bottom: 6rem;
  text-align: center;
  font-weight: 700;
}

@media screen and (max-width: 1024px) {
  .rest .restInfo_ttl {
    margin-bottom: 3rem;
  }
}

.rest .restInfo_txt {
  text-align: center;
  margin-top: -2rem;
  line-height: 1.5;
  margin-bottom: 6rem;
}

@media screen and (max-width: 1024px) {
  .rest .restInfo_txt {
    margin-top: -1rem;
    margin-bottom: 3rem;
  }
}

.rest .restInfo-list {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 641px) {
  .rest .restInfo-list {
    flex-direction: column;
  }
}

@media screen and (min-width: 642px) {
  .rest .restInfo-list li {
    width: calc(50% - (4rem / 2));
  }
  .rest .restInfo-list li:nth-of-type(odd) {
    margin-right: 4rem;
  }
  .rest .restInfo-list li:nth-of-type(n+3) {
    margin-top: 10rem;
  }
}

@media screen and (max-width: 641px) {
  .rest .restInfo-list {
    flex-direction: row;
  }
}

@media screen and (max-width: 641px) {
  .rest .restInfo-list li {
    width: calc(50% - (1rem / 2));
  }
  .rest .restInfo-list li:nth-of-type(odd) {
    margin-right: 1rem;
  }
  .rest .restInfo-list li:nth-of-type(n+3) {
    margin-top: 2rem;
  }
}

@media screen and (min-width: 642px) {
  .rest .restInfo-list + .restInfo_ttl {
    margin-top: 20rem;
  }
}

@media screen and (max-width: 1024px) {
  .rest .restInfo-list + .restInfo_ttl {
    margin-top: calc(20rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .rest .restInfo-list + .restInfo_ttl {
    margin-top: calc(20rem * .5);
  }
}

.rest .restInfo-list li {
  text-align: center;
}

.rest .restInfo-list_ttl {
  font-size: 2rem;
  margin-top: 1rem;
  line-height: 2;
}

.rest .restInfo-list_ttl.bento {
  font-size: 1.5rem;
}

.rest .restInfo-list_txt {
  margin-top: 2rem;
  line-height: 2;
}

.rest .restInfo-list_txt.bento {
  font-size: 1.25rem;
}

.rest .restInfo-gyoza {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (min-width: 1025px) {
  .rest .restInfo-gyoza li {
    width: calc(25% - ((3.5rem * 3) / 4));
  }
  .rest .restInfo-gyoza li:not(:nth-of-type(4n)) {
    margin-right: 3.5rem;
  }
  .rest .restInfo-gyoza li:nth-of-type(n+5) {
    margin-top: 8rem;
  }
}

@media screen and (max-width: 1024px) {
  .rest .restInfo-gyoza li {
    width: calc(50% - (3.5rem / 2));
  }
  .rest .restInfo-gyoza li:nth-of-type(odd) {
    margin-right: 3.5rem;
  }
  .rest .restInfo-gyoza li:nth-of-type(n+3) {
    margin-top: 8rem;
  }
}

@media screen and (max-width: 641px) {
  .rest .restInfo-gyoza li {
    width: calc(50% - (1rem / 2));
  }
  .rest .restInfo-gyoza li:nth-of-type(odd) {
    margin-right: 1rem;
  }
  .rest .restInfo-gyoza li:nth-of-type(n+3) {
    margin-top: 1rem;
  }
}

@media screen and (min-width: 642px) {
  .rest .restInfo-gyoza + .restInfo_ttl {
    margin-top: 15rem;
  }
}

@media screen and (max-width: 1024px) {
  .rest .restInfo-gyoza + .restInfo_ttl {
    margin-top: calc(15rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .rest .restInfo-gyoza + .restInfo_ttl {
    margin-top: calc(15rem * .5);
  }
}

.rest .restInfo-gyoza li {
  text-align: center;
}

.rest .restInfo-gyoza_ttl {
  margin-top: 2rem;
  font-size: 1.5rem;
  line-height: 2;
}

@media screen and (max-width: 1024px) {
  .rest .restInfo-gyoza_ttl {
    margin-top: 1rem;
  }
}

.rest .restInfo-gyoza_txt {
  font-size: 1.25rem;
  margin-top: 2rem;
  line-height: 2;
}

@media screen and (max-width: 1024px) {
  .rest .restInfo-gyoza_txt {
    margin-top: 1rem;
  }
}

.rest .restInfo-souzai {
  max-width: 880px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 642px) {
  .rest .restInfo-souzai {
    margin-top: 15rem;
  }
}

@media screen and (max-width: 1024px) {
  .rest .restInfo-souzai {
    margin-top: calc(15rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .rest .restInfo-souzai {
    margin-top: calc(15rem * .5);
  }
}

.rest .restInfo-souzai-list {
  margin-bottom: 5rem;
}

@media screen and (min-width: 1025px) {
  .rest .restInfo-souzai-list {
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 1025px) and (min-width: 1025px) {
  .rest .restInfo-souzai-list li {
    width: calc(33.333% - ((4.5rem * 2) / 3));
  }
  .rest .restInfo-souzai-list li:not(:nth-of-type(3n)) {
    margin-right: 4.5rem;
  }
  .rest .restInfo-souzai-list li:nth-of-type(n+4) {
    margin-top: 3rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1024px) {
  .rest .restInfo-souzai-list li {
    width: calc(50% - (4.5rem / 2));
  }
  .rest .restInfo-souzai-list li:nth-of-type(odd) {
    margin-right: 4.5rem;
  }
  .rest .restInfo-souzai-list li:nth-of-type(n+3) {
    margin-top: 3rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 641px) {
  .rest .restInfo-souzai-list {
    flex-direction: column;
  }
}

@media screen and (min-width: 1025px) and (max-width: 641px) {
  .rest .restInfo-souzai-list li {
    width: 100%;
  }
  .rest .restInfo-souzai-list li:nth-of-type(odd) {
    margin-right: 0;
  }
  .rest .restInfo-souzai-list li:not(:first-of-type) {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  .rest .restInfo-souzai-list {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: 1024px) {
  .rest .restInfo-souzai-list li {
    width: calc(33.333% - (2rem / 3));
  }
  .rest .restInfo-souzai-list li img {
    width: 100%;
  }
}

.rest .restInfo-souzai .txt {
  text-align: center;
  line-height: 2;
  letter-spacing: .12em;
}

@media screen and (min-width: 642px) {
  .rest .restInfo-orde {
    margin-top: 10rem;
  }
}

@media screen and (max-width: 1024px) {
  .rest .restInfo-orde {
    margin-top: calc(10rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .rest .restInfo-orde {
    margin-top: calc(10rem * .5);
  }
}

.rest .restInfo-orde-img {
  margin-bottom: 5rem;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .rest .restInfo-orde-img {
    margin-bottom: 2rem;
  }
}

.rest .restInfo-orde-img img {
  vertical-align: bottom;
}

.rest .restInfo-orde .txt {
  text-align: center;
  line-height: 2;
  letter-spacing: .12em;
}

/*******************************

    company

*******************************/
.company .companyHead {
  position: relative;
}

@media screen and (min-width: 642px) {
  .company .companyHead {
    padding-bottom: 11rem;
  }
}

@media screen and (max-width: 1024px) {
  .company .companyHead {
    padding-bottom: calc(11rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .company .companyHead {
    padding-bottom: calc(11rem * .5);
  }
}

.company .companyHead_ttl {
  position: absolute;
  right: 7%;
  top: 5rem;
  font-weight: 600;
  letter-spacing: .24em;
  line-height: 2;
  white-space: nowrap;
}

@media screen and (min-width: 1025px) {
  .company .companyHead_ttl {
    writing-mode: vertical-rl;
  }
}

@media screen and (max-width: 1024px) {
  .company .companyHead_ttl {
    width: 100%;
    position: relative;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 3rem;
    right: 0;
    top: 0;
  }
}

.company .companyHead-cont {
  width: 81%;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .company .companyHead-cont {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .company .companyHead-cont_img {
    width: 100%;
  }
}

.company .companyHead-cont_txt {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 38rem;
  line-height: 2.5;
  padding: 4rem 5rem 0;
  background: #FFF;
}

@media screen and (max-width: 1024px) {
  .company .companyHead-cont_txt {
    width: 80%;
    padding: 2.5rem 3rem;
    position: relative;
    margin: 0 auto;
    justify-content: center;
    margin-top: -2.5rem;
  }
}

.company .companyMain {
  max-width: calc(880px + 6rem);
  padding: 0 3rem;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1024px) {
  .company .companyMain {
    max-width: calc(880px + 4rem);
    padding: 0 2rem;
  }
}

.company .companyMain_ttl {
  font-size: 5rem;
  font-weight: 600;
  letter-spacing: .1em;
  margin-bottom: 5rem;
}

@media screen and (max-width: 1024px) {
  .company .companyMain_ttl {
    text-align: center;
  }
}

@media screen and (min-width: 642px) {
  .company .companyInfo {
    margin-bottom: 14rem;
  }
}

@media screen and (max-width: 1024px) {
  .company .companyInfo {
    margin-bottom: calc(14rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .company .companyInfo {
    margin-bottom: calc(14rem * .5);
  }
}

.company .companyInfo_ttl, .company .companyContact_ttl {
  font-size: 3rem;
  letter-spacing: 0.12em;
  font-weight: 600;
  margin-bottom: 3rem;
}

@media screen and (max-width: 1024px) {
  .company .companyInfo_ttl, .company .companyContact_ttl {
    text-align: center;
  }
}

.company .companyInfo-list div {
  display: flex;
  padding: 3rem 0;
  font-size: 1.5rem;
  line-height: 1.5;
  border-bottom: 1px solid #000;
}

.company .companyInfo-list dt {
  width: 16rem;
}

@media screen and (max-width: 1024px) {
  .company .companyInfo-list dt {
    width: 9rem;
  }
}

@media screen and (max-width: 1024px) {
  .company .companyInfo-list dd {
    margin-left: 2rem;
    text-align: left;
  }
}

.company .companyContact {
  padding-bottom: 6rem;
}

@media screen and (max-width: 1024px) {
  .company .companyContact {
    padding-bottom: 3rem;
  }
}

.company .companyContact_txt {
  max-width: 57rem;
  line-height: 2;
  margin-bottom: 6rem;
}

@media screen and (max-width: 1024px) {
  .company .companyContact_txt {
    text-align: center;
    margin-bottom: 4rem;
  }
}

.company .companyContact-list {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 641px) {
  .company .companyContact-list {
    flex-direction: column;
  }
}

@media screen and (min-width: 642px) {
  .company .companyContact-list li {
    width: calc(50% - (6rem / 2));
  }
  .company .companyContact-list li:nth-of-type(odd) {
    margin-right: 6rem;
  }
  .company .companyContact-list li:nth-of-type(n+3) {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 641px) {
  .company .companyContact-list {
    flex-direction: column;
  }
}

@media screen and (max-width: 641px) {
  .company .companyContact-list li {
    width: 100%;
  }
  .company .companyContact-list li:nth-of-type(odd) {
    margin-right: 0;
  }
  .company .companyContact-list li:not(:first-of-type) {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  .company .companyContact-list {
    width: 80%;
    margin: 0 auto;
  }
}

.company .companyContact-list a {
  transition: ease 0.3s;
}

.company .companyContact-list a:hover {
  opacity: 0.6;
}

/*******************************

    contact

*******************************/
.contactMain {
  max-width: calc(880px + 6rem);
  padding: 0 3rem;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1024px) {
  .contactMain {
    max-width: calc(880px + 4rem);
    padding: 0 2rem;
  }
}

.contactMain-inner {
  display: flex;
}

@media screen and (max-width: 1024px) {
  .contactMain-inner {
    flex-direction: column;
  }
}

.contactInfo {
  width: 50%;
  padding-right: 5%;
  flex: 0 0 auto;
}

@media screen and (max-width: 1024px) {
  .contactInfo {
    width: 90%;
    padding-left: 15%;
  }
}

.contactInfo-shop {
  margin-bottom: 8rem;
}

@media screen and (max-width: 1024px) {
  .contactInfo-shop {
    text-align: center;
  }
}

.contactInfo-shop_ttl, .contactInfo-recruit_ttl {
  font-size: 3rem;
  letter-spacing: 0.2em;
  margin-bottom: 7.5rem;
}

@media screen and (max-width: 1024px) {
  .contactInfo-shop_ttl, .contactInfo-recruit_ttl {
    margin-bottom: 4rem;
  }
}

.contactInfo-shop-add:not(:last-of-type) {
  margin-bottom: 6rem;
}

@media screen and (max-width: 1024px) {
  .contactInfo-shop-add:not(:last-of-type) {
    margin-bottom: 4rem;
  }
}

.contactInfo-shop-add_ttl {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.contactInfo-shop-add_ttl span {
  display: block;
  font-size: 1.4rem;
  letter-spacing: 0em;
  margin-top: 2rem;
}

.contactInfo-shop-add_add {
  line-height: 1.5;
  margin-bottom: .5rem;
}

@media screen and (max-width: 1024px) {
  .contactInfo-recruit {
    margin-bottom: 4rem;
  }
}

.contactInfo-recruit_ttl {
  margin-bottom: 6rem;
}

@media screen and (max-width: 1024px) {
  .contactInfo-recruit_ttl {
    margin-bottom: 4rem;
    text-align: center;
  }
}

.contactInfo-recruit li {
  margin-bottom: 2.5rem;
}

@media screen and (max-width: 1024px) {
  .contactInfo-recruit li {
    text-align: center;
  }
}

.contactInfo-recruit a {
  min-width: 18rem;
  justify-content: center;
}

.contactInfo-recruit .ttl {
  margin-bottom: 1.5rem;
}

.contactForm {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .contactForm {
    order: -1;
    margin-bottom: 5rem;
  }
}

.contactForm dl {
  margin-bottom: 3.5rem;
}

.contactForm input[type="text"],
.contactForm input[type="email"],
.contactForm textarea,
.contactForm select {
  font-family: "Noto Serif JP", "游明朝", YuMincho, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.45rem;
  padding: 2.75rem 0;
  line-height: 1.5;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .contactForm input[type="text"],
  .contactForm input[type="email"],
  .contactForm textarea,
  .contactForm select {
    font-size: 16px;
  }
}

.contactForm input[type="text"]::placeholder,
.contactForm input[type="email"]::placeholder,
.contactForm textarea::placeholder {
  color: #000;
}

.contactForm select {
  display: block;
}

.contactForm .box:first-of-type {
  margin-top: .5rem;
  border-top: 1px solid #000;
  position: relative;
}

.contactForm .box:first-of-type::after {
  position: absolute;
  right: 15px;
  display: block;
  content: "";
  width: 0;
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  /* Safari用 */
  transform: translateY(-50%);
  border-style: solid;
  border-width: 12px 7px 0 7px;
  border-color: #333333 transparent transparent transparent;
}

.contactForm .box {
  border-bottom: 1px solid #000;
}

.contactForm input[type="submit"] {
  font-family: "Noto Serif JP", "游明朝", YuMincho, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.6rem;
  padding: 3rem 0;
  text-align: center;
  width: 100%;
  letter-spacing: 0.12em;
  color: #FFF;
  font-weight: 700;
  background: #000;
  border-radius: 1rem;
}

.contactForm .send-box {
  margin-bottom: 4rem;
}

.contactForm_note {
  font-size: 1.2rem;
  margin-bottom: 3rem;
}

/*******************************

    story

*******************************/
.story .storyMain_ttl {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

.story .storyMain_ttl::before, .story .storyMain_ttl::after {
  content: "";
  display: block;
  height: 16px;
  width: 1px;
  background: #000;
  margin-top: 2rem;
}

.story .storyMain_ttl-wrap {
  display: flex;
  align-items: center;
}

.story .storyMain_ttl-wrap::before, .story .storyMain_ttl-wrap::after {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  background: #000;
  margin-top: 2rem;
}

.story .storyMain_ttl span {
  text-align: center;
  letter-spacing: 0.2em;
  padding: 0 4.5rem;
}

.story .storyMain_ttl strong {
  display: block;
  font-size: 4.5rem;
  font-weight: 500;
  margin-top: 0.5rem;
}

@media screen and (max-width: 1024px) {
  .story .storyMain_ttl strong {
    font-size: 3rem;
  }
}

@media screen and (min-width: 642px) {
  .story .storyBox {
    padding-bottom: 12.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .story .storyBox {
    padding-bottom: calc(12.5rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .story .storyBox {
    padding-bottom: calc(12.5rem * .5);
  }
}

@media screen and (max-width: 1024px) {
  .story .storyBox:first-of-type {
    margin-top: -12px;
  }
}

@media screen and (min-width: 1025px) {
  .story .storyBox:first-of-type {
    margin-top: -2.2rem;
    padding-top: 9.5rem;
  }
}

.story .storyBox:nth-of-type(n+2) {
  padding-top: 4rem;
}

.story .storyBox-head {
  position: relative;
}

@media screen and (min-width: 642px) {
  .story .storyBox-head {
    margin-bottom: 10rem;
  }
}

@media screen and (max-width: 1024px) {
  .story .storyBox-head {
    margin-bottom: calc(10rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .story .storyBox-head {
    margin-bottom: calc(10rem * .5);
  }
}

.story .storyBox-head_copy {
  align-items: center;
  font-size: 2rem;
  display: flex;
  letter-spacing: .24em;
  line-height: 2;
  font-weight: 600;
}

@media screen and (min-width: 1025px) {
  .story .storyBox-head_copy {
    writing-mode: vertical-rl;
    position: absolute;
    right: 6%;
    top: 6rem;
    white-space: nowrap;
  }
}

@media screen and (max-width: 1024px) {
  .story .storyBox-head_copy {
    position: relative;
    flex-direction: column;
    order: 1;
    right: 0%;
    text-align: center;
    margin-bottom: 6rem;
  }
}

.story .storyBox-head_copy i {
  margin-bottom: 3rem;
}

@media screen and (max-width: 1024px) {
  .story .storyBox-head_copy i {
    order: -1;
    width: 9rem;
    margin-bottom: 2rem;
  }
}

.story .storyBox-head_img {
  width: 81%;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .story .storyBox-head_img {
    width: 100%;
    margin-bottom: 3rem;
  }
}

.story .storyBox-cont {
  max-width: calc(880px + 6rem);
  padding: 0 3rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

@media screen and (max-width: 1024px) {
  .story .storyBox-cont {
    max-width: calc(880px + 4rem);
    padding: 0 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .story .storyBox-cont {
    display: flex;
    flex-direction: column;
    margin-left: 0rem;
  }
}

@media screen and (min-width: 1025px) {
  .story .storyBox-cont-inner:first-of-type {
    width: 44%;
    flex: 0 0 auto;
  }
}

@media screen and (max-width: 1024px) {
  .story .storyBox-cont-inner:first-of-type {
    width: 100%;
  }
}

.story .storyBox-cont_ttl {
  margin-bottom: 2rem;
  font-size: 1.3rem;
}

.story .storyBox-cont_copy {
  font-size: 2.5rem;
  line-height: 1.7;
  letter-spacing: .025em;
}

@media screen and (max-width: 1024px) {
  .story .storyBox-cont_copy {
    margin-bottom: 3rem;
  }
}

.story .storyBox-cont_copy span {
  display: block;
  font-size: 1.3rem;
  margin-top: 1rem;
  line-height: 1.8;
}

.story .storyBox-cont_txtbox {
  margin-bottom: 4rem;
}

.story .storyBox-cont_txtbox p {
  line-height: 2.2;
}

.story .storyBox-cont_txtbox p:not(:last-of-type) {
  margin-bottom: 4rem;
}

.story .storyBox-cont_poem {
  display: flex;
  align-items: flex-start;
  line-height: 2.2;
  flex: 0 0 auto;
  font-weight: 600;
}

@media screen and (min-width: 1025px) {
  .story .storyBox-cont_poem {
    white-space: nowrap;
  }
}

.story .storyBox-cont_poem::before {
  content: "";
  display: block;
  width: 5rem;
  height: 1px;
  background: #000;
  margin-top: 1em;
  margin-right: 1rem;
}

.story .storyBox-cont_more {
  margin-top: 5rem;
}

@media screen and (min-width: 1025px) {
  .story .storyBox:nth-of-type(odd) .storyBox-head_img {
    margin: 0 0 0 auto;
  }
}

@media screen and (min-width: 1025px) {
  .story .storyBox:nth-of-type(odd) .storyBox-head_copy {
    right: inherit;
    left: 6%;
  }
}

.story .storyBox-o {
  background: #fdfaf8;
  background: -webkit-linear-gradient(-45deg, #fdfaf8 0%, #d5a47a 100%);
  background: linear-gradient(135deg, #fdfaf8 0%, #d5a47a 100%);
}

.story .storyBox-p {
  background: #ffffff;
  background: -webkit-linear-gradient(-45deg, #ffffff 10%, #cd837b 100%);
  background: linear-gradient(135deg, #ffffff 10%, #cd837b 100%);
}

.story .storyBox-g {
  background: #ffffff;
  background: -webkit-linear-gradient(left, #ffffff 1%, #b9c8aa 100%);
  background: linear-gradient(to right, #ffffff 1%, #b9c8aa 100%);
}

@media screen and (max-width: 1024px) {
  .story .storyBox-g {
    padding-top: 7rem;
  }
}

/*******************************

    ARCHIVE - NEWS,ALT

*******************************/
@media screen and (min-width: 642px) {
  .arcMain {
    margin-bottom: 10rem;
  }
}

@media screen and (max-width: 1024px) {
  .arcMain {
    margin-bottom: calc(10rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .arcMain {
    margin-bottom: calc(10rem * .5);
  }
}

.arcMain-list {
  max-width: calc(880px + 6rem);
  padding: 0 3rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 1024px) {
  .arcMain-list {
    max-width: calc(880px + 4rem);
    padding: 0 2rem;
  }
}

@media screen and (min-width: 642px) {
  .arcMain-list {
    margin-bottom: 15rem;
  }
}

@media screen and (max-width: 1024px) {
  .arcMain-list {
    margin-bottom: calc(15rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .arcMain-list {
    margin-bottom: calc(15rem * .5);
  }
}

@media screen and (max-width: 641px) {
  .arcMain-list {
    flex-direction: column;
  }
}

@media screen and (min-width: 642px) {
  .arcMain-list li {
    width: calc(50% - (6rem / 2));
  }
  .arcMain-list li:nth-of-type(odd) {
    margin-right: 6rem;
  }
  .arcMain-list li:nth-of-type(n+3) {
    margin-top: 10rem;
  }
}

@media screen and (max-width: 641px) {
  .arcMain-list {
    flex-direction: column;
  }
}

@media screen and (max-width: 641px) {
  .arcMain-list li {
    width: 100%;
  }
  .arcMain-list li:nth-of-type(odd) {
    margin-right: 0;
  }
  .arcMain-list li:not(:first-of-type) {
    margin-top: 3rem;
  }
}

.arcMain-list figure {
  position: relative;
  width: 100%;
  overflow: hidden;
  background: #000;
  background: #FFF;
  overflow: hidden;
}

.arcMain-list figure img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.arcMain-list figure img {
  width: 101%;
  height: auto;
  max-width: 101%;
}

@supports (object-fit: cover) {
  .arcMain-list figure img {
    width: 101%;
    height: 101%;
    object-fit: cover;
  }
}

.arcMain-list figure::after {
  content: "";
  display: block;
  padding-top: 58%;
}

.arcMain-list a {
  display: block;
  width: 100%;
  height: 100%;
  transition: ease 0.3s;
}

.arcMain-list a:hover {
  opacity: 0.6;
}

.arcMain-list span {
  display: block;
}

.arcMain-list-cont {
  position: relative;
  margin-top: -3rem;
  padding: 4rem 3.5rem 3.5rem;
  width: 75%;
  margin: 0 -1rem 0 auto;
  background: #FFF;
  box-shadow: -5px 5px 20px rgba(0, 0, 0, 0.3);
}

.arcMain-list-cont_cat {
  font-size: 1.5rem;
  position: absolute;
  top: 0;
  left: 0;
  letter-spacing: .2em;
  padding: .5rem 1.25rem;
  background: #000;
  color: #FFF;
}

.arcMain-list-cont_cat.cat-info, .arcMain-list-cont_cat.cat-wine {
  background: #BA5349;
}

.arcMain-list-cont_cat.cat-event, .arcMain-list-cont_cat.cat-party {
  background: #526B2A;
}

.arcMain-list-cont_cat.cat-dinner {
  background: #be7333;
}

.arcMain-list-cont_ttl {
  line-height: 1.5;
  font-size: 1.6rem;
}

.arcMain-list-cont_day {
  margin-top: 1.5rem;
  font-size: 1.3rem;
}

.arcMain .pager .nav-links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.arcMain .pager .nav-links a,
.arcMain .pager .nav-links span {
  margin: 0 1.25rem;
  padding: 0 0 .5rem;
}

.arcMain .pager .nav-links .current {
  border-bottom: 1px solid #000;
}

.arcMain .pager .nav-links a {
  transition: ease 0.3s;
}

.arcMain .pager .nav-links a:hover {
  opacity: 0.6;
}

@media screen and (min-width: 1025px) {
  .newsMain_ttl {
    margin-bottom: 8rem;
  }
}

.alterMain_ttl {
  text-align: center;
  font-size: 3rem;
  font-weight: 500;
}

@media screen and (min-width: 642px) {
  .alterMain_ttl {
    margin-bottom: 10rem;
  }
}

@media screen and (max-width: 1024px) {
  .alterMain_ttl {
    margin-bottom: calc(10rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .alterMain_ttl {
    margin-bottom: calc(10rem * .5);
  }
}

.alterMain-cat-list {
  max-width: calc(705px + 6rem);
  padding: 0 3rem;
  margin-left: auto;
  margin-right: auto;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 1024px) {
  .alterMain-cat-list {
    max-width: calc(705px + 4rem);
    padding: 0 2rem;
  }
}

@media screen and (min-width: 642px) {
  .alterMain-cat-list {
    margin-bottom: 8rem;
  }
}

@media screen and (max-width: 1024px) {
  .alterMain-cat-list {
    margin-bottom: calc(8rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .alterMain-cat-list {
    margin-bottom: calc(8rem * .5);
  }
}

@media screen and (min-width: 1025px) {
  .alterMain-cat-list li {
    width: calc(25% - ((3.5rem * 3) / 4));
  }
  .alterMain-cat-list li:not(:nth-of-type(4n)) {
    margin-right: 3.5rem;
  }
  .alterMain-cat-list li:nth-of-type(n+5) {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 1024px) {
  .alterMain-cat-list li {
    width: calc(50% - (3.5rem / 2));
  }
  .alterMain-cat-list li:nth-of-type(odd) {
    margin-right: 3.5rem;
  }
  .alterMain-cat-list li:nth-of-type(n+3) {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 641px) {
  .alterMain-cat-list li {
    width: calc(50% - (1rem / 2));
  }
  .alterMain-cat-list li:nth-of-type(odd) {
    margin-right: 1rem;
  }
  .alterMain-cat-list li:nth-of-type(n+3) {
    margin-top: 1rem;
  }
}

.alterMain-cat-list li {
  min-width: 15rem;
}

.alterMain-cat-list li a {
  background: #000;
  align-items: center;
  display: flex;
  align-items: center;
  display: inline-flex;
  color: #FFF;
  height: 3rem;
  border-radius: 1.5rem;
  padding: 0 1.5rem;
  line-height: 3rem;
  font-weight: 600;
  transition: ease 0.3s;
  font-size: 1.6rem;
  white-space: nowrap;
}

.alterMain-cat-list li a:after {
  content: "";
  display: block;
  background: url(../img/icon-arrow.svg) center bottom no-repeat;
  width: .5rem;
  height: 1.2rem;
  margin-left: .75rem;
}

.alterMain-cat-list li a:hover {
  opacity: 0.6;
}

.alterMain-cat-list li a {
  width: 100%;
  justify-content: space-between;
  text-align: center;
}

.alterMain-cat-list li a::after {
  flex: 0 0 auto;
}

.alterMain-cat-list li span {
  display: block;
  text-align: center;
  width: 100%;
}

.alterMain-cat-list li.is-active a {
  opacity: 0.4;
  pointer-events: none;
}

.alterMain .arcMain-list-cont_ttl {
  padding-top: 1rem;
  font-size: 1.4rem;
}

/*******************************

    SINGLE - NEWS,ALT

*******************************/
.singleMain {
  max-width: calc(880px + 6rem);
  padding: 0 3rem;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1024px) {
  .singleMain {
    max-width: calc(880px + 4rem);
    padding: 0 2rem;
  }
}

@media screen and (min-width: 642px) {
  .singleMain {
    margin-bottom: 8rem;
  }
}

@media screen and (max-width: 1024px) {
  .singleMain {
    margin-bottom: calc(8rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .singleMain {
    margin-bottom: calc(8rem * .5);
  }
}

.singleMain .pankuzu {
  margin-bottom: 4.5rem;
}

.singleMain_cat {
  font-size: 1.5rem;
  letter-spacing: .2em;
  padding: .5rem 1.25rem;
  background: #000;
  color: #FFF;
  display: inline-flex;
  margin-bottom: 3rem;
}

.singleMain_cat.cat-info, .singleMain_cat.cat-wine {
  background: #BA5349;
}

.singleMain_cat.cat-event, .singleMain_cat.cat-party {
  background: #526B2A;
}

.singleMain_cat.cat-dinner {
  background: #be7333;
}

.singleMain_day {
  font-size: 1.3rem;
  margin-bottom: 1.5rem;
}

.singleMain_ttl {
  line-height: 1.5;
  font-size: 3rem;
  margin-bottom: 5rem;
}

@media screen and (min-width: 642px) {
  .singleMain-cont {
    margin-bottom: 8rem;
  }
}

@media screen and (max-width: 1024px) {
  .singleMain-cont {
    margin-bottom: calc(8rem * .85);
  }
}

@media screen and (max-width: 641px) {
  .singleMain-cont {
    margin-bottom: calc(8rem * .5);
  }
}

@media screen and (max-width: 1024px) {
  .singleMain-cont {
    margin-bottom: 6rem;
  }
}

.singleMain-cont a {
  text-decoration: underline;
}

.singleMain-cont strong,
.singleMain-cont b {
  font-weight: 900;
}

.singleMain-cont > img {
  width: 100%;
  display: block;
}

.singleMain-cont > img + h3,
.singleMain-cont > img + div,
.singleMain-cont > img + ul,
.singleMain-cont > img + img,
.singleMain-cont > ul + h3,
.singleMain-cont > ul + div,
.singleMain-cont > ul + ul,
.singleMain-cont > ul + img,
.singleMain-cont > div:not(.info) + h3,
.singleMain-cont > div:not(.info) + div,
.singleMain-cont > div:not(.info) + ul,
.singleMain-cont > div:not(.info) + img {
  margin-top: 5rem;
}

@media screen and (max-width: 1024px) {
  .singleMain-cont > img + h3,
  .singleMain-cont > img + div,
  .singleMain-cont > img + ul,
  .singleMain-cont > img + img,
  .singleMain-cont > ul + h3,
  .singleMain-cont > ul + div,
  .singleMain-cont > ul + ul,
  .singleMain-cont > ul + img,
  .singleMain-cont > div:not(.info) + h3,
  .singleMain-cont > div:not(.info) + div,
  .singleMain-cont > div:not(.info) + ul,
  .singleMain-cont > div:not(.info) + img {
    margin-top: 3rem;
  }
}

.singleMain-cont .flow {
  max-width: 840px;
  margin-left: auto;
  margin-right: auto;
  padding: 4.5rem;
  border: 1px solid #000;
}

@media screen and (max-width: 1024px) {
  .singleMain-cont .flow {
    padding: 2rem;
  }
}

.singleMain-cont .flow-ttl {
  line-height: 1.5;
  padding-bottom: 0.25rem;
  margin-bottom: 1.75rem;
  border-bottom: 1px solid #000;
}

.singleMain-cont .flow ul {
  display: block;
}

.singleMain-cont .flow li {
  line-height: 1.5;
}

.singleMain-cont .flow li:not(:last-of-type) {
  margin-bottom: 1.5rem;
}

.singleMain-cont .ttl {
  line-height: 2.25;
  font-size: 2rem;
  margin-bottom: 3rem;
}

.singleMain-cont div {
  line-height: 2.25;
}

.singleMain-cont ul:not(.flow-list) {
  display: flex;
  justify-content: space-between;
}

.singleMain-cont ul:not(.flow-list) li {
  width: 48.5%;
}

.singleMain-cont .info {
  background: #f6ebe2;
  padding: 3rem;
}

.singleMain-cont .info h3 {
  font-size: 1.2rem;
  color: #be7333;
}

.singleMain-postbtn {
  max-width: calc(750px + 6rem);
  padding: 0 3rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1024px) {
  .singleMain-postbtn {
    max-width: calc(750px + 4rem);
    padding: 0 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .singleMain-postbtn {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 1024px) {
  .singleMain-postbtn_prev, .singleMain-postbtn_next {
    margin-bottom: 1.5rem;
  }
}

.singleMain-postbtn_prev a, .singleMain-postbtn_next a {
  width: 200px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .singleMain-postbtn_prev a, .singleMain-postbtn_next a {
    width: 100%;
    min-height: 2.3rem;
    justify-content: center;
  }
}

.singleMain-postbtn_prev i, .singleMain-postbtn_next i {
  display: block;
  width: 2.3rem;
  height: 2.3rem;
  flex: 0 0 auto;
}

@media screen and (max-width: 1024px) {
  .singleMain-postbtn_prev {
    order: 0;
  }
}

.singleMain-postbtn_prev i {
  background: url(../img/icon-prev.svg) center center no-repeat;
  background-size: 2.3rem auto;
  margin-right: 0.5rem;
}

@media screen and (min-width: 1025px) {
  .singleMain-postbtn_prev span {
    width: 200px;
    display: block;
  }
}

@media screen and (max-width: 1024px) {
  .singleMain-postbtn_next {
    order: 1;
  }
}

.singleMain-postbtn_next a {
  justify-content: flex-end;
}

.singleMain-postbtn_next i {
  background: url(../img/icon-next.svg) center center no-repeat;
  background-size: 2.3rem auto;
  margin-left: 0.5em;
}

.singleMain-postbtn_back a {
  background: #000;
  align-items: center;
  display: flex;
  align-items: center;
  display: inline-flex;
  color: #FFF;
  height: 3rem;
  border-radius: 1.5rem;
  padding: 0 1.5rem;
  line-height: 3rem;
  font-weight: 600;
  transition: ease 0.3s;
  font-size: 1.6rem;
  white-space: nowrap;
}

.singleMain-postbtn_back a:after {
  content: "";
  display: block;
  background: url(../img/icon-arrow.svg) center bottom no-repeat;
  width: .5rem;
  height: 1.2rem;
  margin-left: .75rem;
}

.singleMain-postbtn_back a:hover {
  opacity: 0.6;
}

@media screen and (max-width: 1024px) {
  .singleMain-postbtn_back {
    order: 2;
    margin-top: 2rem;
  }
}

.singleMain-postbtn_back a {
  color: #000;
  border: 1px solid #000;
  background: #FFF;
}

.singleMain-postbtn_back a::after {
  display: none;
}

/*******************************

    js

*******************************/
@media screen and (min-width: 1025px) {
  .vbox-container img {
    max-height: calc(90vh - 40px);
  }
}

.vbox-close {
  right: inherit;
  left: 0;
}

/*******************************

     ANIMATION

*******************************/
.f01 {
  text-align: center;
  transition: all 0.5s cubic-bezier(0.43, 0.13, 0.56, 0.72);
  transform: scale(0.5);
  transform: translateY(1rem);
  opacity: 0;
}

.f01.frame-in {
  transform: scale(1);
  opacity: 1;
  transform: translateY(0);
}

.f02 {
  position: relative;
}

.f02::after {
  transition: all 0.75s cubic-bezier(0.43, 0.13, 0.56, 0.72);
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: #fefdf8;
  transform-origin: left bottom;
  transform: scaleX(1);
}

.f02.frame-in::after {
  width: 0;
  transform: scaleX(0);
}

.f05 {
  transition: all 1.75s cubic-bezier(0.43, 0.13, 0.56, 0.72);
  position: relative;
  opacity: 0;
}

.f05::after {
  transition: all 1.5s ease-out;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: #fefdf8;
  transform-origin: left bottom;
  transform: scaleX(1);
}

.f05.frame-in {
  opacity: 1;
}

.f05.frame-in::after {
  width: 0;
  transform: scaleX(0);
}

.f03 {
  transition: opacity 0.5s cubic-bezier(0.43, 0.13, 0.56, 0.72);
  opacity: 0;
}

.f03.frame-in {
  opacity: 1;
}

.f03-2 {
  transition: opacity 0.5s cubic-bezier(0.43, 0.13, 0.56, 0.72) 0.5s;
  opacity: 0;
}

.f03-2.frame-in {
  opacity: 1;
}

.f04 {
  position: relative;
}

.f04::after {
  content: "";
  display: block;
  background: #f3e645;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transition: transform 0.5s cubic-bezier(0.43, 0.13, 0.56, 0.72);
  transform-origin: left bottom;
  transform: scaleX(1);
}

.f04 p {
  opacity: 0;
  transition: opacity .5s ease .5s;
}

.f04.frame-in::after {
  width: 0;
  transform: scaleX(0);
}

.f04.frame-in p {
  opacity: 1;
}

/*******************************

    header

*******************************/
.head {
  background: #FFF;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  display: flex;
}

@media screen and (min-width: 1025px) {
  .head {
    padding: 0 6rem;
    justify-content: space-between;
    height: 8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1160px) {
  .head {
    padding: 0 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .head {
    height: 80px;
    align-items: center;
    padding-left: 1rem;
  }
}

@media screen and (max-width: 641px) {
  .head {
    height: 50px;
  }
}

@media screen and (min-width: 1025px) {
  .head-logo {
    padding-top: 1rem;
  }
  .head-logo.is-loaded {
    opacity: 1;
  }
  .head-logo a {
    transition: ease 0.3s;
  }
  .head-logo a:hover {
    opacity: 0.6;
  }
}

@media screen and (max-width: 1024px) {
  .head-logo img {
    width: 20rem;
  }
}

.menu-main {
  font-family: "Noto Serif JP", "游明朝", YuMincho, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-weight: 600;
  font-style: normal;
}

@media screen and (min-width: 1025px) {
  .menu-main {
    height: 4.5rem;
    padding-right: 11rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1160px) {
  .menu-main {
    padding-right: 13rem;
  }
}

@media screen and (min-width: 1025px) {
  .menu-main .menu-inner,
  .menu-main nav {
    height: 100%;
  }
  .menu-main ul {
    display: flex;
    height: 100%;
  }
  .menu-main li {
    display: flex;
    align-items: flex-end;
    height: 100%;
    padding: 0 2rem;
  }
  .menu-main li:not(:last-of-type) {
    border-right: 1px solid #000;
  }
  .menu-main li:last-of-type {
    padding-right: 0;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1399px) {
  .menu-main li {
    padding: 0 1rem;
  }
}

@media screen and (min-width: 1025px) {
  .menu-main a:not(.bnr) {
    font-size: 1.6rem;
    letter-spacing: 0.12em;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1399px) {
  .menu-main a:not(.bnr) {
    font-size: 1.3rem;
  }
}

@media screen and (min-width: 1025px) {
  .menu-main a:not(.bnr)::after {
    content: "";
    display: block;
    width: 0;
    height: 1px;
    background: #000;
    transition: 0.3s;
  }
  .menu-main a:not(.bnr):hover::after {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .menu-main {
    display: none;
    z-index: 90002;
    opacity: 0;
    transition: ease .3s;
  }
  .menu.is-open .menu-main {
    font-size: 2rem;
    display: block;
    position: fixed;
    background: rgba(255, 255, 255, 0.95);
    top: 0;
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    left: 0;
  }
  .menu.is-open .menu-main nav > ul li:not(:last-of-type) {
    margin-bottom: 3rem;
  }
  .menu.is-open .menu-main nav > ul a {
    font-size: 2rem;
    padding: .5rem 0 1.5rem;
    line-height: 1;
    display: block;
    width: 100%;
    border-bottom: 1px solid #1a1a1a;
    color: #000;
  }
  .menu.is-open .menu-main .menu-inner,
  .menu.is-open .menu-main nav {
    width: 100%;
  }
  .menu.is-open .menu-inner {
    padding: 6.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
  }
  .menu.is-open.is-active .menu-main {
    opacity: 1;
  }
  .menu.is-open.is-active .menu-inner {
    transform: translate3d(0%, 0, 0);
  }
  .head-menu_btn {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99999;
    width: 80px;
    height: 80px;
    background: #1a1a1a;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

@media screen and (max-width: 1024px) and (max-width: 641px) {
  .head-menu_btn {
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .head-menu_btn i {
    width: 42px;
  }
}

@media screen and (max-width: 1024px) and (max-width: 641px) {
  .head-menu_btn i {
    width: 24px;
  }
}

@media screen and (max-width: 1024px) {
  .head-menu_btn span {
    height: 1px;
    background: #FFF;
    display: block;
    transition: 0.3s;
  }
  .head-menu_btn span:first-of-type {
    width: 100%;
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 1024px) and (max-width: 641px) {
  .head-menu_btn span:first-of-type {
    margin-bottom: 6px;
  }
}

@media screen and (max-width: 1024px) {
  .head-menu_btn span:nth-of-type(2) {
    width: 100%;
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 1024px) and (max-width: 641px) {
  .head-menu_btn span:nth-of-type(2) {
    margin-bottom: 6px;
  }
}

@media screen and (max-width: 1024px) {
  .head-menu_btn.menu-open span {
    margin: 0;
  }
  .head-menu_btn.menu-open span:first-of-type {
    transform: rotate(45deg);
    margin: 0;
  }
  .head-menu_btn.menu-open span:nth-of-type(2) {
    display: none;
  }
  .head-menu_btn.menu-open span:last-of-type {
    transform: rotate(-45deg);
    width: 100%;
    margin: -1px 0 0 0px;
  }
}

@media screen and (min-width: 1025px) {
  .menu-sns {
    position: fixed;
    right: 1.4rem;
    top: 16.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .menu-sns {
    margin-top: 4rem;
  }
}

@media screen and (max-width: 1024px) {
  .menu-sns-list {
    display: flex;
    justify-content: center;
  }
}

.menu-sns-list li {
  margin-bottom: 2.5rem;
}

@media screen and (max-width: 1024px) {
  .menu-sns-list li {
    margin: 0 1.5rem;
    display: flex;
    align-items: center;
    transform: scale(1.25);
  }
}

.menu-sns-list a {
  display: block;
  width: 1.6rem;
  text-align: center;
  transition: ease 0.3s;
}

.menu-sns-list a:hover {
  opacity: 0.6;
}

@media screen and (max-width: 1024px) {
  .menu-sns-list a {
    display: flex;
    align-items: center;
    width: 2rem;
  }
}

@media screen and (min-width: 1025px) {
  .menu-bnr {
    position: absolute;
    right: 0;
    top: 0;
  }
}

@media screen and (max-width: 1024px) {
  .menu-bnr {
    margin-top: 5rem;
  }
}

@media screen and (min-width: 1025px) {
  .menu-bnr a {
    transition: ease 0.3s;
  }
  .menu-bnr a:hover {
    opacity: 0.6;
  }
}

@media screen and (min-width: 1025px) {
  .menu-bnr img {
    width: 14rem;
  }
}
